import { createPostRequestOpts, fetchJsonQuery } from "@mne-core/api";
import { QueryController, createUrl } from "@mne-core/api";
export const createRequest = ({ ...restCriteria }, opts) => {
    const { apiUrl, sessionKey, ...restOpts } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "enroll", "authentication"]).toString();
    return new Request(uri, createPostRequestOpts(JSON.stringify(restCriteria), { ...restOpts }));
};
export const verifyEnrollmentAndAuthorize = async (criteria, opts) => fetchJsonQuery(createRequest(criteria, opts), {
    ctrl: opts.ctrl || new QueryController(),
});
