import { QueryController, createGetRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = ({ ...restCriteria }, opts) => {
    const { apiUrl } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "openid", "provider", "userlink"], restCriteria).toString();
    return new Request(uri, createGetRequestOpts(opts));
};
export async function requestOpenIdProviderLink(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
