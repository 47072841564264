import { QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import CurrentUserURI from "./CurrentUserURIBuilder";
export const createRequest = (funcName, customerId, opts) => {
    const { apiUrl, apikey, ...restOpts } = opts;
    const url = new CurrentUserURI().setApiUrl(apiUrl).setSegment(["permission", funcName, customerId.toString()]);
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
    });
    let localRequestOpts = {
        headers,
        method: "GET",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(url), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function hasPermission(funcName, customerId, opts) {
    return await fetchJsonQuery(createRequest(funcName, customerId, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default hasPermission;
