import { defineMessages } from "@mnemonic/intl";
export const messages = defineMessages({
    email: { defaultMessage: "Your email at your organization", id: "email" },
    helperText: {
        defaultMessage: "You will be sent an login uri so that you can login with your organization",
        id: "helperText",
    },
    missingEmail: { defaultMessage: "Email is required", id: "missingEmail" },
    invalidEmail: { defaultMessage: "Invalid email format", id: "invalidEmail" },
});
export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
export const validateEmail = (email, intl) => {
    if (!email)
        return intl.formatMessage(messages.missingEmail);
    if (!isValidEmail(email))
        return intl.formatMessage(messages.invalidEmail);
    return undefined;
};
