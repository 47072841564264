import { QueryController, createPostRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = (criteria, opts) => {
    const { apiUrl } = opts;
    const url = createUrl(apiUrl, ["authentication", "v1", "password", "authentication"]).toString();
    return new Request(url, createPostRequestOpts(JSON.stringify(criteria), opts));
};
async function authenticatePassword(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authenticatePassword;
