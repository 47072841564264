const NAME = "HttpError";
class HttpError extends Error {
    static isHttpError(ex) {
        return ex && ex.name === NAME;
    }
    request;
    response;
    constructor(message, args) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HttpError);
        }
        this.name = NAME;
        // Custom debugging information
        this.request = args.request;
        this.response = args.response;
    }
}
export default HttpError;
