import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
import PolicyDialog from "../../../../../Components/GDPRPolicyDialog";
import { commonMessages } from "../../../../../commonMessages";
import ContactUsDialog from "../../../../ContactUsDialog";
const LeftSidebarDisclaimer = ({ parentTheme }) => {
    const theme = useTheme();
    // ------- Dialog states -------
    const [openPoilicyDialog, setOpenPoilicyDialog] = useState(false);
    const [openContactUsDialog, setOpenContactUsDialog] = useState(false);
    const linkProps = {
        onClick: () => setOpenPoilicyDialog(true),
        onKeyPress: () => setOpenPoilicyDialog(true),
        tabIndex: 0,
    };
    return (<Box sx={{ textAlign: "center" }}>
      <ThemeProvider theme={parentTheme}>
        <PolicyDialog {...{ open: openPoilicyDialog, onClose: () => setOpenPoilicyDialog(false) }}/>
        <ContactUsDialog {...{ open: openContactUsDialog, onClose: () => setOpenContactUsDialog(false) }}/>
      </ThemeProvider>
      <Box>
        <Typography variant="body2" color={theme.palette.text.primary}>
          <FormattedMessage id="login.problems" defaultMessage="You can see our service status on"/>
          &nbsp;
          <Link href="https://mnemonic.statuspage.io/" target="_blank" rel="noopener noreferrer" sx={{ cursor: "pointer" }}>
            mnemonic.statuspage.io
          </Link>
        </Typography>
      </Box>
      <Box sx={{ mt: theme.spacing(1) }}>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          <FormattedMessage id="login.by.logging.in" defaultMessage="By logging in you agree to our"/>
          &nbsp;
          <Link sx={{ cursor: "pointer", color: theme.palette.text.secondary }} {...linkProps}>
            <FormattedMessage id="login.cookie.policy" defaultMessage="cookie policy"/>
          </Link>
        </Typography>
      </Box>
      <Box sx={{ mt: theme.spacing(1) }}>
        <Button color="primary" variant="outlined" sx={{ height: 36, width: "100%" }} onClick={() => setOpenContactUsDialog(true)}>
          <FormattedMessage {...commonMessages.ContactUs}/>
        </Button>
      </Box>
    </Box>);
};
export default LeftSidebarDisclaimer;
