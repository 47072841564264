import React from "react";
import { Field } from "react-final-form";
import MuiTextField from "@mui/material/TextField";
import mapFinalFormMetaToErrorProps from "./mapFinalFormMetaToErrorProps";
function TextField(props) {
    const { name, validate, FieldProps: FieldPropsProp = {}, variant = "outlined", ...restProps } = props;
    const fieldProps = {
        ...FieldPropsProp,
        name,
        validate,
    };
    return (<Field {...fieldProps}>
      {({ input, meta }) => {
            const { helperText, ...filteredProps } = restProps;
            const textFieldProps = {
                ...mapFinalFormMetaToErrorProps(meta),
                name,
                variant,
                ...input,
                ...filteredProps,
            };
            if (!textFieldProps.helperText) {
                textFieldProps.helperText = helperText;
            }
            return <MuiTextField {...textFieldProps}/>;
        }}
    </Field>);
}
export default TextField;
