/**
 * Redirects the user back to the original place they came from, passing the authorization token they came to fetch.
 * @param {number} options.payload.responseCode - The response code indicating the result of the operation.
 * @param {string} options.payload.data.authorization - The authorization token.
 *
 * @example
 * // Redirect with a successful response code
 * redirectWithToken({
 *   nextURI: '/original-page',
 *   payload: {
 *     responseCode: 200,
 *     data: {
 *       authorization: 'authToken123',
 *     },
 *   },
 * });
 *
 * @example
 * // No redirect for an unsuccessful response code
 * redirectWithToken({
 *   nextURI: '/original-page',
 *   payload: {
 *     responseCode: 404,
 *     data: {},
 *   },
 * });
 */
export const redirectWithToken = ({ nextURI, payload }) => {
    if (payload?.responseCode === 200 || payload?.responseCode === 201) {
        window.location.replace(`${nextURI}?authorization=${payload?.data?.authorization ?? ""}`);
    }
};
