import { QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import CurrentUserURI from "./CurrentUserURIBuilder";
export const createRequest = (opts) => {
    const { apiUrl, apikey, ...restOpts } = opts;
    const url = new CurrentUserURI().setApiUrl(apiUrl).setSegment(["user"]);
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
        "Content-type": "application/json",
    });
    let localRequestOpts = {
        headers,
        method: "GET",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(url), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function fetchUser(opts) {
    return await fetchJsonQuery(createRequest(opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default fetchUser;
