import { QueryController, createPostRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = (criteria, opts) => {
    const { apiUrl } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "sms", "authentication"]).toString();
    return new Request(uri, createPostRequestOpts(JSON.stringify(criteria), opts));
};
/**
 * Performs SMS authentication by submitting the specified criteria to the server.
 * This function supports a two-step authentication process. In the first step, with mode 'PASSWORD',
 * the function submits the username and static password. The server responds with a challenge and a session cookie.
 * In the second step, with mode 'VERIFICATION', it submits the username, the received SMS code as passcode,
 * and the session cookie. The function then awaits an authentication result.
 *
 * Requires SMS authentication to be enabled on the server and for the user. Use `/methods` to check which
 * authentication methods are available on the server.
 *
 * @async
 * @param {IAuthenticateSMSCriteria} criteria - The criteria for SMS authentication.
 * @param {IRequestOpts} opts - Additional request options, including API URL and controller.
 * @returns {Promise<JsonQuery<IResultstash<IAuthenticationData>>>} A promise resolving to the authentication result.
 */
export async function authenticateSMS(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authenticateSMS;
