import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { isSpaPath, normalizePath } from "./urlTools";
export const DEFAULT_NEXT = "/spa/dashboard/";
export const CHANGE_PW_URL = "/spa/authentication/changepassword/";
/**
 * Redirect the user user to the correct next url
 */
const redirectUser = ({ nextUrl, changePasswordNeeded }) => {
    // Check if a password change is required
    if (changePasswordNeeded) {
        window.location.replace(CHANGE_PW_URL);
        return;
    }
    // If no next is provided, redirect to the default next path
    if (!nextUrl) {
        window.location.replace(DEFAULT_NEXT);
        return;
    }
    const { pathname, search, hash } = new URL(getArgusRedirectUrl(nextUrl));
    window.location.replace(`${pathname}${search}${hash}`);
};
const getArgusRedirectUrl = (nextUrl, location = window.location) => {
    const { baseUrl } = ArgusUtils.getTargetMeta(location);
    //the nextUrl can be a path or a full URL. We need to ensure we are only getting the path
    let nextPath;
    try {
        const { pathname, search, hash } = new URL(nextUrl);
        nextPath = `${pathname}${search}${hash}`;
    }
    catch {
        nextPath = normalizePath(nextUrl);
    }
    //Check if the path is already a spa path. If so, prepend the base url to the path
    //If it is not a spa path, convert it to a spa path by default
    if (isSpaPath(nextPath)) {
        return new URL(`${baseUrl}${nextPath}`).toString();
    }
    else {
        return ArgusUtils.toSpaUrl(nextPath, location);
    }
};
export default redirectUser;
export { getArgusRedirectUrl };
