/* eslint-disable */
import URI from "urijs";
const ensureTailingSlash = (s) => (s.substr(-1) === "/" ? s : `${s}/`);
const getStaticUrl = (hostname) => {
    if (WEBPACK_SERVE) {
        return location.origin;
    }
    switch (hostname) {
        case "localhost":
            return location.origin;
        case "localhost.mnemonic.no":
        case "osl-argusdev-portal1.mnemonic.no":
            return `https://osl-argus-trunk-static1.mnemonic.no/mnemonic/v1/${__PACKAGE__NAME__}/versions/${__PACKAGE__VERSION__}/`;
        case "osl-argus-staging-web1.mnemonic.no":
        case "argusweb.mnemonic.no":
        case "portal.mnemonic.no":
        case "portal.nfcert.org":
        default:
            return `https://static.mnemonic.no/mnemonic/v1/${__PACKAGE__NAME__}/versions/${__PACKAGE__VERSION__}/`;
    }
};
// Not the slash(/) on the end. Extremly important. Otherwise webpack will concat the folder and filename together
__webpack_public_path__ = ensureTailingSlash(getStaticUrl(location.hostname));
