import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { fetchOauthClients, requestOpenIdProviderLink, requestRedirectURIByProviderID, submitOpenIDAuthCodeByProviderID, } from "@mne-authentication/api";
import { getSessionKey } from "../utils/getSessionKey";
import { baseApi } from "./baseApi";
const openIdProviderApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        requestOpenIdProviderLink: build.query({
            queryFn: ({ criteria, metaOpts }) => handleResponse(requestOpenIdProviderLink(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        requestRedirectURIByProviderID: build.query({
            queryFn: ({ criteria, metaOpts }) => handleResponse(requestRedirectURIByProviderID(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        fetchOauthClients: build.query({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(fetchOauthClients(criteria, { apiUrl: ArgusUtils.getApiUrl(), sessionKey: getSessionKey(api) }), metaOpts),
        }),
        /* Mutations */
        submitOpenIDAuthCodeByProviderID: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(submitOpenIDAuthCodeByProviderID(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
    }),
});
export const { useRequestOpenIdProviderLinkQuery, useFetchOauthClientsQuery, useRequestRedirectURIByProviderIDQuery, useSubmitOpenIDAuthCodeByProviderIDMutation, } = openIdProviderApi;
export default openIdProviderApi;
