import { JsonQuery, QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "../authentication/AuthenticationURIBuilder";
import omitNullishProperties from "../utils/omitNullishProperties";
import CurrentUserURI from "./CurrentUserURIBuilder";
const CACHE_FUNCTIONS_KEY = "CacheFunctions";
async function fetchSession(queryParams, opts) {
    const { apiUrl, ctrl: optsCtrl, apikey, useSessionCache } = opts;
    const ctrl = optsCtrl || new QueryController();
    const url = new AuthenticationURI().setApiUrl(apiUrl).setSegment(["session"]);
    const headers = createHeaders({
        Accept: "application/json",
        "ARGUS-API-KEY": apikey,
    });
    const sessionQuery = await fetchJsonQuery(new Request(url.setQuery(omitNullishProperties(queryParams)).toString(), {
        credentials: "include",
        headers,
        method: "GET",
    }), { ctrl });
    const functionsCache = sessionStorage.getItem(CACHE_FUNCTIONS_KEY);
    const functionQueryPromise = fetchJsonQuery(new Request(new CurrentUserURI().setApiUrl(apiUrl).setSegment(["functions"]).setSearch({ limit: 0 }).toString(), {
        credentials: "include",
        headers,
        method: "GET",
    }), { ctrl }).then((functionQuery) => {
        const functions = functionQuery.json.data;
        if (useSessionCache) {
            sessionStorage.setItem(CACHE_FUNCTIONS_KEY, JSON.stringify(functions));
        }
        return functions;
    });
    const functionPromise = useSessionCache && functionsCache
        ? Promise.resolve(JSON.parse(functionsCache))
        : functionQueryPromise;
    return Promise.all([
        fetchJsonQuery(new Request(new CurrentUserURI().setApiUrl(apiUrl).setSegment(["user"]).toString(), {
            credentials: "include",
            headers,
            method: "GET",
        }), { ctrl }),
        functionPromise,
    ]).then(([userQuery, functions]) => 
    /*
      We construct a bogus request.
      To do that we use the request\response object
      from the session query since its the actual
      query that determines if you have a session or not
    */
    new JsonQuery(sessionQuery.request, sessionQuery.response, {
        functions,
        info: sessionQuery.json.data,
        user: userQuery.json.data,
    }, ctrl));
}
export default fetchSession;
