const arrayBufferToString = (arrayBuffer) => btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
/**
 * Handles the common logic for making a request with the WebAuthn API and processing the response.
 * @param {PublicKeyCredentialRequestOptions} criteria - Criteria for the WebAuthn request.
 * @returns {Promise<Partial<IWebauthnAuthenticateCriteria & IAuthorizeWebauthnCriteria>>} - Partial criteria common to both authentication and authorization processes.
 */
const requestWebAuthnCredential = async (criteria) => {
    const assertion = await navigator.credentials.get({ publicKey: criteria });
    const { response, rawId } = assertion;
    const { authenticatorData, clientDataJSON, signature } = response;
    return {
        authenticatorData: arrayBufferToString(authenticatorData),
        clientData: arrayBufferToString(clientDataJSON),
        signature: arrayBufferToString(signature),
        webauthnKeyID: arrayBufferToString(rawId),
    };
};
export const createAuthenticationRequest = async ({ webauthnAuthenticationResponse, domain, userName, }) => {
    const challengeID = webauthnAuthenticationResponse?.challengeID;
    const criteria = transformAuthResponseToPublicKeyCredentialRequestOptions(webauthnAuthenticationResponse);
    const commonData = await requestWebAuthnCredential(criteria);
    const authenticationCriteria = {
        ...commonData,
        challengeID,
        domain,
        userName,
    };
    return authenticationCriteria;
};
export const createAuthorizeRequest = async ({ webauthnAuthorizationResponse, nextURI, operation, }) => {
    const criteria = transformAuthResponseToPublicKeyCredentialRequestOptions(webauthnAuthorizationResponse);
    const challengeID = webauthnAuthorizationResponse?.challengeID;
    const commonData = await requestWebAuthnCredential(criteria);
    const authorizationCriteria = {
        ...commonData,
        challengeID,
        nextURI,
        operation,
    };
    return authorizationCriteria;
};
/**
 * Transforms authentication response data into a format suitable for initiating a WebAuthn authentication request.
 *
 * @param {Omit<IPrepareWebAuthnAuthenticationResponse, "challengeID">} authResponse - The authentication preparation response, excluding the challengeID.
 * @returns {PublicKeyCredentialRequestOptions} The public key credential request options required to initiate the WebAuthn authentication process.
 */
export const transformAuthResponseToPublicKeyCredentialRequestOptions = ({ challenge, permittedWebAuthnKeyIDs, timeout, }) => {
    return {
        challenge: base64ToUint8Array(challenge),
        timeout,
        allowCredentials: permittedWebAuthnKeyIDs.map((keyID) => ({
            id: base64ToUint8Array(keyID),
            type: "public-key",
        })),
    };
};
/**
 * Decodes a base64 string to a Uint8Array.
 *
 * @param {string} base64 - The base64 encoded string to decode.
 * @returns {Uint8Array} The decoded data as a Uint8Array.
 */
const base64ToUint8Array = (base64) => {
    return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
};
