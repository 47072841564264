import { QueryController, createCsrfToken, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequestAuthorize = (body, opts) => {
    const { apiUrl, apiKey, sessionKey, ...restOpts } = opts;
    const url = new AuthenticationURI().setApiUrl(apiUrl).setSegment(["totp", "authorize"]);
    const { operation, context, nextURI, tokenCode } = body;
    const headers = createHeaders({
        "ARGUS-API-KEY": apiKey,
        Accept: "application/json",
        "Content-type": "application/json",
        "ARGUS-CSRF-Token": !apiKey ? createCsrfToken(sessionKey, String(url)) : false,
    });
    const localRequestOpts = {
        body: JSON.stringify({ operation, context, nextURI, tokenCode }),
        credentials: "include",
        headers,
        method: "POST",
        mode: "cors",
    };
    return new Request(String(url), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function authorizeTotp(body, opts) {
    return await fetchJsonQuery(createRequestAuthorize(body, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authorizeTotp;
