import React from "react";
import Button from "@mui/material/Button";
import { FormattedMessage } from "@mnemonic/intl";
import { StyledDisclaimerWrapper } from "../Pages/Login/StyledComponets";
import Disclaimer from "./GDPRDisclaimer";
export const LoginButtonWithDisclaimer = ({ disableButton }) => {
    return (<StyledDisclaimerWrapper>
      <Disclaimer />
      <Button type="submit" variant="contained" color="secondary" disabled={disableButton}>
        <FormattedMessage id="login.next" defaultMessage="Next"/>
      </Button>
    </StyledDisclaimerWrapper>);
};
