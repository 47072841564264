import { QueryController, createCsrfToken, createPutRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = ({ ...restCriteria }, opts) => {
    const { apiUrl, sessionKey, ...restOpts } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "password"]).toString();
    return new Request(uri, createPutRequestOpts(JSON.stringify(restCriteria), { ...restOpts, csrfToken: createCsrfToken(sessionKey, uri) }));
};
async function updateUserPassword(body, opts) {
    return await fetchJsonQuery(createRequest(body, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default updateUserPassword;
