import { QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (criteria, opts) => {
    const { apiUrl, apikey, ...restOpts } = opts;
    const { provider, state, origin } = criteria;
    const uri = new AuthenticationURI()
        .setApiUrl(apiUrl)
        .setSegment(["openid", "provider", provider, "authentication"])
        .setQuery({ state })
        .build();
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
        "Content-type": "application/json",
        Origin: origin,
    });
    let localRequestOpts = {
        headers,
        method: "GET",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function requestRedirectURIByProviderID(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default requestRedirectURIByProviderID;
