import React, { useState } from "react";
import { LoginBox, LoginBoxContent, LoginBoxHeader } from "../../Components/Login/Layout";
import { LoginSMSUsernamePassword } from "./LoginSMSUsernamePassword";
import { LoginSMSValidateToken } from "./LoginSMSValidateToken";
import RedirectWrapper from "./RedirectWrapper";
export const LoginSms = () => {
    const [smsStepState, setSmsStepState] = useState({
        step: 1,
        userName: null,
        cookie: null,
    });
    return (<RedirectWrapper>
      <LoginBox>
        <LoginBoxHeader />
        <LoginBoxContent>
          {smsStepState.step === 1 && <LoginSMSUsernamePassword setSmsStepState={setSmsStepState}/>}
          {smsStepState.step === 2 && <LoginSMSValidateToken smsStepState={smsStepState}/>}
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
