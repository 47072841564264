// @flow
// Last updated: 22.06.18
// taken from: https://www.mnemonic.no/about/privacy-notice/cookies-notice/
import React from "react";
import Typography from "@mui/material/Typography";
const Text = () => {
    return (<Typography component="div" sx={{
            "& em": {
                color: "rgba(0, 0, 0, 0.54)",
                fontStyle: "normal",
            },
            "& table": {
                marginBottom: 3,
                marginTop: 3,
                width: "100%",
            },
        }}>
      The customer portal sets the following cookies to identify the authenticated user:
      <table style={{ border: 0 }}>
        <tbody>
          <tr>
            <td>
              <p>
                <em>Cookie name</em>
              </p>
            </td>
            <td>
              <p>
                <em>Expiration time</em>
              </p>
            </td>
            <td>
              <p>
                <em>Description</em>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>JSESSIONID</strong>
              </p>
            </td>
            <td>
              <p>Session</p>
            </td>
            <td>
              <p>Temporary session identifier</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>argus_username</strong>
              </p>
            </td>
            <td>
              <p>Session</p>
            </td>
            <td>
              <p>Username of current user session</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>argus_cookie</strong>
              </p>
            </td>
            <td>
              <p>Session</p>
            </td>
            <td>
              <p>Current user session secret</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>argus_last_method</strong>
              </p>
            </td>
            <td>
              <p>Unlimited</p>
            </td>
            <td>
              <p>Last used authentication method</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>
                  login.lastUsername&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
              </p>
            </td>
            <td>
              <p>Unlimited</p>
            </td>
            <td>
              <p>Last used username</p>
            </td>
          </tr>
        </tbody>
      </table>
      In addition, we use localstorage to improve the user experience across browser sessions, by storing last used
      browser settings on different browser pages. These data items do not contain any personal/sensitive information.
    </Typography>);
};
export default Text;
