import React from "react";
import { VerifyBox } from "../../Components/Login/Layout/Verify/VerifyBox";
import RedirectWrapper from "../Login/RedirectWrapper";
import { EnrollmentStepper } from "./EnrollmentStepper";
import { StepComplete } from "./StepComplete";
import { StepDefinePassword } from "./StepDefinePassword";
import { StepVerifySmsCode } from "./StepVerifySmsCode";
import { StepVerifyUser } from "./StepVerifyUser";
import { useVerifyUsernamePassword } from "./useVerifyUsernamePassword";
export const VerifyUsernamePassword = () => {
    const props = useVerifyUsernamePassword();
    const currentStep = props.enrollFlowState.step.kind;
    return (<RedirectWrapper>
      <VerifyBox {...props}>
        <EnrollmentStepper {...props}/>
        {currentStep === "verifyUser" && <StepVerifyUser {...props}/>}
        {currentStep === "verifySmsCode" && <StepVerifySmsCode {...props}/>}
        {currentStep === "definePassword" && <StepDefinePassword {...props}/>}
        {currentStep === "complete" && <StepComplete />}
      </VerifyBox>
    </RedirectWrapper>);
};
