import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import Dialog from "@mnemonic/mui5/Dialog";
import DialogContent from "@mnemonic/mui5/DialogContent";
import { formattedPhoneNumber } from "@mnemonic/pure-utils";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { commonMessages } from "../commonMessages";
const ContactUsDialog = (props) => {
    const { open, onClose } = props;
    const theme = useTheme();
    const metaInfo = ArgusUtils.getMetaInfo();
    const addressParts = metaInfo?.address?.split(", ");
    return (<Dialog {...{ open, onClose, fullWidth: true, maxWidth: "xs" }}>
      <DialogTitle>
        <FormattedMessage {...commonMessages.ContactUs}/>
      </DialogTitle>
      <DialogContent sx={{ borderLeft: `${theme.spacing(3)} solid ${theme.palette.secondary.main}`, pb: 0, pl: 1, mb: "20px" }}>
        <Typography gutterBottom color="secondary" variant="body2">
          {metaInfo?.name}
        </Typography>
        <Typography>{addressParts?.[0]}</Typography>
        {addressParts.length > 1 && <Typography>{addressParts?.slice(1).join(", ")}</Typography>}
        <Typography>{metaInfo?.email}</Typography>
        <>
          {metaInfo?.phoneNumber?.map((number, index) => {
            // Handled by formattedPhoneNumber()
            const nationalNumber = number?.replace("+47", "");
            return (<Typography key={number}>
                {index === 0 && "Tel: "}
                {index === 1 && "Alt: "}
                {index > 1 && `Alt ${index}: `}
                {formattedPhoneNumber({ nationalNumber, countryCode: "47" })}
              </Typography>);
        })}
        </>
      </DialogContent>
    </Dialog>);
};
export default ContactUsDialog;
