import { QueryController, createCsrfToken, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (body, opts) => {
    const { apiUrl, apiKey, sessionKey, ...restOpts } = opts;
    const uri = new AuthenticationURI().setApiUrl(apiUrl).setSegment(["ldap", "authorize"]);
    const { operation, password, nextURI, context } = body;
    const headers = createHeaders({
        "ARGUS-API-KEY": apiKey,
        Accept: "application/json",
        "Content-type": "application/json",
        "ARGUS-CSRF-Token": !apiKey ? createCsrfToken(sessionKey, String(uri)) : false,
    });
    const localRequestOpts = {
        body: JSON.stringify({ operation, password, nextURI, context }),
        credentials: "include",
        headers,
        method: "POST",
        mode: "cors",
    };
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function authorizeLdap(body, opts) {
    return await fetchJsonQuery(createRequest(body, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authorizeLdap;
