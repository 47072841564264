import React from "react";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import { useIntl } from "@mnemonic/intl";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxActions, LoginBoxCallout } from "../../Components/Login/Layout";
import { PasswordField } from "../../Components/PasswordTextField";
import { useAuthorizeLdapMutation } from "../../Redux/Service/authorizeApi";
import { commonMessages } from "../../commonMessages";
import { redirectWithToken } from "./utils/redirectWithToken";
export const AuthorizationWithLDAP = (props) => {
    const { operation, context, nextURI } = props;
    const { formatMessage } = useIntl();
    const [authorizeLdap, { isLoading, error }] = useAuthorizeLdapMutation();
    const errorMessage = extractErrorMessage(error);
    return (<Form initialValues={{ password: null }} onSubmit={(formValues, formApi) => authorizeLdap({ criteria: { ...formValues, operation, context, nextURI } })
            .unwrap()
            ?.then((payload) => redirectWithToken({ nextURI, payload }))
            // on error reset password field
            ?.catch((error) => {
            formApi.change("password", "");
            console.error(error);
        })} render={({ handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
            {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
            <PasswordField name="password" method="ad3"/>
            <LoginBoxActions>
              <div>
                <Button disabled={isLoading} style={{ marginRight: 8 }} onClick={() => window.location.replace(nextURI)}>
                  {formatMessage(commonMessages.Cancel)}
                </Button>
                <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                  {formatMessage(commonMessages.Authorize)}
                </Button>
              </div>
            </LoginBoxActions>
          </form>);
        }}/>);
};
