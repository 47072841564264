import { styled } from "@mui/material/styles";
export const StyledSingleFieldWrapper = styled("div")(({ theme }) => ({
    paddingBottom: theme.spacing(2),
}));
export const StyledMultiFieldWrapper = styled("div")(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
}));
export const StyledTokenFieldWrapper = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(2),
}));
export const StyledDisclaimerWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
}));
