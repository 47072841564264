import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { fetchAvailableAuthMethods } from "@mne-authentication/api";
import fetchAuthenticationMethodsForUser from "@mne-authentication/api/src/authentication/fetchAuthMethodsForUser";
import { baseApi } from "./baseApi";
const methodsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAvailableAuthMethod: build.query({
            queryFn: ({ metaOpts }) => handleResponse(fetchAvailableAuthMethods({ apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        fetchAuthenticationMethodsForUser: build.query({
            queryFn: ({ userId, metaOpts }) => handleResponse(fetchAuthenticationMethodsForUser(userId, {
                apiUrl: ArgusUtils.getApiUrl(),
                sessionKey: metaOpts?.sessionKey,
            }), metaOpts),
        }),
    }),
});
export const { useFetchAvailableAuthMethodQuery, useFetchAuthenticationMethodsForUserQuery } = methodsApi;
export default methodsApi;
