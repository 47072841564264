import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { FormattedMessage } from "@mnemonic/intl";
import Dialog from "@mnemonic/mui5/Dialog";
import DialogContent from "@mnemonic/mui5/DialogContent";
import Text from "./GDPRText";
const PolicyDialogComp = (props) => {
    const { open, onClose } = props;
    return (<Dialog {...{ open, onClose }}>
      {/* Not translation on the header since the text is not translated  */}
      <DialogTitle>Cookie policy</DialogTitle>
      <DialogContent>
        <Text />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="login.close" defaultMessage="Close"/>
        </Button>
      </DialogActions>
    </Dialog>);
};
export default PolicyDialogComp;
