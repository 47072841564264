import { QueryController, createPostRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequestAuthenticate = (criteria, opts) => {
    const { apiUrl } = opts;
    const url = createUrl(apiUrl, ["authentication", "v1", "totp", "authentication"]).toString();
    return new Request(url, createPostRequestOpts(JSON.stringify(criteria), opts));
};
async function authenticateTotp(criteria, opts) {
    return await fetchJsonQuery(createRequestAuthenticate(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authenticateTotp;
