import { useEffect, useState } from "react";
/**
 * Calculates the remaining time in minutes until a given expiration timestamp.
 * If the expiration time has already passed, it returns 0.
 *
 * @param {number} expirationTimestamp - The expiration timestamp in milliseconds.
 * @returns {number} - The remaining time in minutes, or 0 if the timestamp has already expired.
 *
 * @example
 * const expirationTimestamp = Date.now() + 60000; // 1 minute from now
 * const timeLeftInMinutes = getMinutesLeft(expirationTimestamp);
 * // timeLeftInMinutes could be 1, and then counts down to 0 when expired.
 */
const getMinutesLeft = (expirationTimestamp) => {
    const now = Date.now();
    return Math.max(Math.floor((expirationTimestamp - now) / 1000 / 60), 0);
};
/**
 * A custom hook that calculates the remaining time based on the expiration timestamp.
 *
 * @param {number} expirationTimestamp - The expiration time as a Unix timestamp in milliseconds.
 * @returns {number} - The remaining time in minutes.
 */
export const useMinutesLeft = (expirationTimestamp) => {
    const [timeLeft, setTimeLeft] = useState(() => getMinutesLeft(expirationTimestamp));
    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = getMinutesLeft(expirationTimestamp);
            if (newTimeLeft <= 0) {
                clearInterval(timer);
                setTimeLeft(0);
            }
            else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000); // Update every second
        return () => clearInterval(timer);
    }, [expirationTimestamp]);
    return timeLeft;
};
export default useMinutesLeft;
