import React, { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { FormattedMessage } from "@mnemonic/intl";
import { FilterBox, FilterBoxActions, FilterBoxLabel } from "@mnemonic/mui5";
const Domain = (props) => {
    const { options, error, onChange } = props;
    const [domain, setDomain] = useState([]);
    // Filterbox state
    const [open, setOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState([]);
    const handleFilterBoxClick = (event) => {
        setAnchorElement(event.currentTarget);
        setOpen(true);
    };
    const handleCloseFilterBox = () => {
        setOpen(false);
        setAnchorElement(null);
    };
    const onApplyFilter = () => {
        setOpen(false);
        setDomain(selectedDomain.map((domain) => domain.name));
        onChange(selectedDomain.map((domain) => domain.name));
    };
    const isActive = Boolean(domain.length === 1);
    return (<FilterBox state={isActive ? (error ? "error" : "active") : "normal"} onClose={() => handleCloseFilterBox()} anchorEl={anchorElement} anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
        }} transformOrigin={{
            horizontal: "center",
            vertical: "top",
        }} open={open} onClick={(event) => handleFilterBoxClick(event)} label={<FormattedMessage id="client.overview.domain" defaultMessage="Domain"/>} LabelComponentProps={{ active: isActive, secondary: domain.length === 1 && domain.toString() }} LabelComponent={FilterBoxLabel}>
      <FormGroup>
        {options?.map((domain, i) => {
            return (<FormControlLabel key={i} control={<Checkbox checked={selectedDomain?.indexOf(domain) > -1} onChange={(e, checked) => setSelectedDomain(checked ? [...selectedDomain, domain] : selectedDomain.filter((d) => d !== domain))} value={domain.name}/>} label={domain.name}/>);
        })}
      </FormGroup>
      <FilterBoxActions>
        <Button onClick={() => handleCloseFilterBox()}>
          <FormattedMessage id="Cancel"/>
        </Button>
        <Button onClick={() => onApplyFilter()}>
          <FormattedMessage id="apply" defaultMessage="Apply"/>
        </Button>
      </FilterBoxActions>
    </FilterBox>);
};
export default Domain;
