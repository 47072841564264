import { QueryController, createPostRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = (criteria, opts) => {
    const { apiUrl } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "signature", "webauthn", "authentication"]).toString();
    return new Request(uri, createPostRequestOpts(JSON.stringify(criteria), opts));
};
async function authenticateWebAuthn(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authenticateWebAuthn;
