import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ArgusMenu, getMenuConfig, permissionCheckerUtil } from "@mnemonic/argus-menu";
import { IntlProvider } from "@mnemonic/intl";
import Logger from "@mnemonic/logging";
import { SnackbarProvider } from "@mnemonic/mui5";
import { Authenticated } from "@mne-authentication/session2";
import ApplicationSession from "./Components/ApplicationSession";
import InnerErrorBoundary from "./Components/ErrorBoundaries/InnerErrorBoundary";
import TopLevelErrorBoundary from "./Components/ErrorBoundaries/TopLevelErrorBoundary";
import { en, nb } from "./i18n/all";
import { ThemeProvider } from "./utils/ThemeProvider";
const Application = ({ intl, target, children, session }) => {
    return (<TopLevelErrorBoundary>
      <ApplicationSession session={session}>
        <Authenticated required={false}>
          {(session, sessionCtx) => {
            /* Hold off rendering until the session is verified. Splash screen should be displayed */
            if (sessionCtx.loading) {
                return null;
            }
            return (<ThemeProvider domain={session?.user?.domain} locale={intl.locale} session={session}>
                <IntlProvider locale={intl.locale} messages={intl.locale === "nb-NO" ? nb : en}>
                  <Authenticated required={false}>
                    {() => (<BrowserRouter basename={__PACKAGE__BASENAME__}>
                        <ArgusMenu {...{
                    ...permissionCheckerUtil(session, getMenuConfig(target.domain, session?.user, intl.locale, Logger.getInstance())),
                    logger: Logger.getInstance(),
                }}>
                          <SnackbarProvider>
                            <InnerErrorBoundary>{children}</InnerErrorBoundary>
                          </SnackbarProvider>
                        </ArgusMenu>
                      </BrowserRouter>)}
                  </Authenticated>
                </IntlProvider>
              </ThemeProvider>);
        }}
        </Authenticated>
      </ApplicationSession>
    </TopLevelErrorBoundary>);
};
export default Application;
