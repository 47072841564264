import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import { Link as CoreLink } from "@mnemonic/mui5";
const getStyles = (theme) => ({
    link: {
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "underline",
    },
    headerCell: {
        display: "inline",
        cursor: "pointer",
    },
});
const Table = (props) => {
    const { clients, domain, loading, onChange } = props;
    const theme = useTheme();
    const styles = getStyles(theme);
    const [sortBy, setSortBy] = useState("name");
    useEffect(() => {
        onChange(sortBy);
    }, [sortBy]);
    return (<TableContainer component={Paper}>
      {loading && <LinearProgress color="secondary"/>}
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <div style={{ ...styles.headerCell }} onClick={() => {
            sortBy === "name" ? setSortBy("-name") : setSortBy("name");
        }}>
                {(sortBy === "name" || sortBy === "-name") && (<TableSortLabel active={true} direction={sortBy.includes("-") ? "desc" : "asc"}/>)}
                <FormattedMessage id="client.overview.name" defaultMessage="Name"/>
              </div>
            </TableCell>
            <TableCell>
              <FormattedMessage id="client.overview.url" defaultMessage="URL"/>
            </TableCell>
            <TableCell>
              <FormattedMessage id="client.overview.description" defaultMessage="Description"/>
            </TableCell>
            <TableCell>
              <FormattedMessage id="Customer" defaultMessage="Customer"/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client) => (<TableRow key={client.name}>
              <TableCell component="th" scope="row">
                <Typography>{client.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <CoreLink target="_blank" href={client.mainURI}>
                    {client.mainURI}
                  </CoreLink>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{client.description}</Typography>
              </TableCell>
              <TableCell>
                {client.customer ? (<ListItemText primary={client?.customer?.name} secondary={
                // Only show the domain if it's not the same as the current users
                [
                    domain.name === client?.customer?.domain?.name ? undefined : client?.customer?.domain?.name,
                    client?.customer?.shortName,
                ]
                    .filter(Boolean)
                    .join("/")}/>) : (<Typography color="textSecondary">
                    {<FormattedMessage id="client.overview.non.customer.specific" defaultMessage="Non customer specific"/>}
                  </Typography>)}
              </TableCell>
            </TableRow>))}
        </TableBody>
      </MuiTable>
    </TableContainer>);
};
export default Table;
