import { FORM_ERROR } from "final-form";
import { toSpaUrl } from "@mnemonic/pure-utils";
import { extractErrorMessage, extractFieldErrorMessage } from "@mnemonic/redux-utils";
import { getAvailableMethods } from "../Authenticate/utils";
export const preferredAuthenticationMethods = ["SIGNATURE", "TOTP"];
/**
 * Maps backend errors to form fields.
 *
 * Extracts error messages for specified fields, where field names must match the backend's error keys.
 * If no field-specific errors are found, a general form error is returned.
 *
 * @param {unknown} e - The error object from the backend.
 * @param {string[]} fields - Array of field names, matching the backend's error keys.
 * @returns {Record<string, string | null> | { FORM_ERROR: string }}
 *          - Field-specific errors or a general form error.
 *
 * @note The field names provided in the `fields` array must exactly match the error keys returned by the backend.
 * If they don't, no field-specific errors will be mapped, and the general form error will be returned instead.
 */
export const mapErrors = (e, fields) => {
    const supportedFieldErrors = fields.reduce((acc, field) => {
        acc[field] = extractFieldErrorMessage(e, field);
        return acc;
    }, {});
    if (Object.entries(supportedFieldErrors).some(([, value]) => Boolean(value))) {
        return supportedFieldErrors;
    }
    return { [FORM_ERROR]: extractErrorMessage(e) || JSON.stringify(e, null, 2) };
};
/**
 * Checks if the user has any preferred authentication methods enabled but not initialized.
 * This method then helps us help the user finalizing the authentication configuration
 *
 * @param {IAuthenticationMethod[]} methods - The list of available authentication methods.
 * @returns {boolean} - Returns `true` if any preferred authentication methods are enabled but not initialized, otherwise `false`.
 *
 * @example
 * const methods = [
 *   { method: 'TOTP', enabled: true, initialized: false, lastLoginTimestamp: 12345 },
 *   { method: 'SMS', enabled: true, initialized: true, lastLoginTimestamp: 12345 },
 * ];
 * const result = userHasUninitializedPreferredAuthMethods(methods); // true
 */
export const userHasUnConfiguredPreferredAuthMethods = (methods) => {
    return methods
        ?.filter((method) => method.enabled && !method.initialized)
        ?.some((method) => preferredAuthenticationMethods.includes(method.method));
};
export const createAuthenticationListItems = (availableAuthMethods, authorization) => {
    const availableGoodAuthenticationMethods = availableAuthMethods
        ?.filter(({ method, enabled }) => enabled && preferredAuthenticationMethods.includes(method))
        ?.map((item) => item.method);
    const listItemsWithModifiedLink = getAvailableMethods(availableGoodAuthenticationMethods)?.map((item) => ({
        ...item,
        to: toSpaUrl(`/user/profile?authorization=${authorization}`),
    }));
    return listItemsWithModifiedLink;
};
export const setSessionStorage = (method) => {
    const action = method === "TOTP" ? "totpInit" : "webauthn";
    const storage = window.sessionStorage;
    const stringifiedState = JSON.stringify({ action });
    storage.setItem("state", stringifiedState);
};
