import React from "react";
import BlockIcon from "@mui/icons-material/Block";
import WarningIcon from "@mui/icons-material/Warning";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { emphasize } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { InfoIcon } from "@mnemonic/mui5/icons";
// Same info color as Snackbar
// https://github.com/mui-org/material-ui/blob/v1-beta/src/Snackbar/SnackbarContent.js#L12
const getStyles = (theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
    },
    icon: {
        height: 24,
        marginRight: theme.spacing(2),
        width: 24,
    },
    info: {
        backgroundColor: emphasize(theme.palette.background.default, theme.palette.mode === "light" ? 0.8 : 0.98),
        color: theme.palette.getContrastText(emphasize(theme.palette.background.default, theme.palette.mode === "light" ? 0.8 : 0.98)),
    },
    warning: {
        backgroundColor: theme.palette.grey[300],
    },
    root: {
        color: theme.palette.common.white,
        display: "flex",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
    },
});
const typeToIcon = {
    error: BlockIcon,
    info: InfoIcon,
    warning: WarningIcon,
    undefined: null,
};
const LoginBoxCallout = ({ styleOverrides = {}, type = "info", children }) => {
    const Icon = typeToIcon[type];
    const theme = useTheme();
    const styles = getStyles(theme);
    return (<Paper sx={{ ...styles.root, ...styles[type], ...styleOverrides }} elevation={0}>
      <Icon color={type === "warning" ? "disabled" : "inherit"} sx={{ ...styles.icon }}/>
      <Typography color="inherit" variant="subtitle1">
        {children}
      </Typography>
    </Paper>);
};
export default LoginBoxCallout;
