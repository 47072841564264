import URI from "urijs";
export const getAuthorizeParameters = (href) => {
    const uri = new URI(href);
    const queryParameters = URI.parseQuery(uri.search());
    const fragmentParameters = URI.parseQuery(uri.fragment());
    return { ...queryParameters, ...fragmentParameters };
};
export const buildURLBasedOnSearchValues = (responseData, responseType) => {
    const validResponseTypes = [
        "code",
        "id_token",
        "token",
        "code,id_token",
        "code,token",
        "id_token,token",
        "code,id_token,token",
    ];
    // Type guard for responseType
    if (!validResponseTypes.includes(responseType)) {
        throw new Error("Invalid responseType");
    }
    const { authorizationCode, state, idToken, accessToken, redirectURI } = responseData;
    const encodedState = encodeURIComponent(state);
    const fragmentParams = [];
    const delimiter = responseType.includes("token") || responseType.includes("id_token") ? "#" : "?";
    const responseTypes = responseType.split(",");
    if (responseTypes.includes("code")) {
        fragmentParams.push(`code=${encodeURIComponent(authorizationCode)}`);
    }
    if (responseTypes.includes("id_token")) {
        fragmentParams.push(`id_token=${encodeURIComponent(idToken)}`);
    }
    if (responseTypes.includes("token")) {
        fragmentParams.push(`access_token=${encodeURIComponent(accessToken)}`);
        fragmentParams.push("token_type=Bearer");
    }
    fragmentParams.push(`state=${encodedState}`);
    return `${redirectURI}${delimiter}${fragmentParams.join("&")}`;
};
export const concatURLStringAndRedirect = (loginUrl, searchValues) => {
    return (window.location.href = String(new URI(loginUrl).addSearch(searchValues)));
};
