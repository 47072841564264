import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { FormattedMessage } from "@mnemonic/intl";
import AuthMethodButton from "./ButtonBase";
export const OpenIDIcon = ({ opacity = "1.0", ...SvgIconPropsOverrides }) => (<SvgIcon {...SvgIconPropsOverrides}>
    <g id="Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity={opacity}>
      <g id="Artboard" transform="translate(-2.000000, -2.000000)" fill="#000000">
        <polygon id="Path-2" points="14.1503207 19.8621521 11.1029387 21.2818298 11.1029387 4.23541241 14.1503207 2.71817017"></polygon>
        <path d="M14.6468597,8.3421771 L14.6468597,10.2767641 C15.2299922,10.3633802 15.7991571,10.4972842 16.3543543,10.678476 C16.9095515,10.8596678 17.3911897,11.0998918 17.7992688,11.3991479 L16.1661524,12.3163719 L22,13.5052768 L21.6840316,9.20216705 L20.0440833,10.1041708 C19.2851952,9.60908427 18.4748176,9.22726375 17.6129506,8.95870921 C16.7510837,8.69015467 15.7623867,8.48464397 14.6468597,8.3421771 Z" id="Path-3"></path>
        <path d="M10.5937988,8.3421771 L10.5937988,10.2827035 C9.19299941,10.5240659 8.05565684,10.9363663 7.18177109,11.5196047 C5.87094246,12.3944622 4.99301289,13.5989481 4.99301289,14.7032584 C4.99301289,15.8075687 5.87243155,17.3588617 7.36511682,18.1842925 C8.36024033,18.7345796 9.60618095,19.127686 11.1029387,19.3636117 L11.1029387,21.2818298 C8.78273903,21.0200953 6.91536892,20.4690707 5.50082834,19.6287561 C3.37901747,18.3682842 2,16.8131065 2,14.7032584 C2,12.5934104 3.94420007,10.6705984 5.93097874,9.67004224 C7.25549785,9.00300479 8.8097712,8.56038308 10.5937988,8.3421771 Z" id="Path-4"></path>
      </g>
    </g>
  </SvgIcon>);
export const OpenIDButton = ({ onClick }) => (<AuthMethodButton onClick={onClick} Icon={OpenIDIcon} title={<FormattedMessage id="login.openid" defaultMessage="Single sign on with OpenID"/>} description={<FormattedMessage id="login.help.method.openid" defaultMessage="Login with OpenID Connect"/>}/>);
export default OpenIDButton;
