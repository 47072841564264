import { createSlice } from "@reduxjs/toolkit";
import queryString from "query-string";
import URI from "urijs";
export const defaultState = {
    disableLastMethodForward: false,
    initialized: false,
    lastMethod: null,
    lastUsername: null,
    loginSuccess: false,
    nextUrl: null,
    queryMessage: null,
};
export const globalSlice = createSlice({
    name: "global",
    initialState: defaultState,
    reducers: {
        setNextUrl: (state, { payload }) => {
            state.nextUrl = payload;
        },
        setQueryMessage: (state, { payload }) => {
            state.queryMessage = payload;
        },
        setLoginSuccess: (state, action) => {
            state.loginSuccess = action.payload;
        },
        setDisableLastMethodForward: (state, { payload }) => {
            state.disableLastMethodForward = payload;
        },
        initializeGlobal(state, { payload }) {
            const location = payload;
            const parsedQueryString = queryString.parse(location.search);
            const isOauthRequest = parsedQueryString.redirect_uri !== undefined &&
                parsedQueryString.response_type !== undefined &&
                parsedQueryString.client_id !== undefined;
            const concatURLString = (loginUrl, searchValues) => {
                return String(new URI(loginUrl).addSearch(searchValues));
            };
            const removeKey = (object, key) => {
                const { [key]: deletedKey, ...otherKeys } = object;
                return otherKeys;
            };
            if (isOauthRequest) {
                // We don't want the "next" parameter to be concated with rest of the search, so we remove it before concatenate the url"
                const next = concatURLString(parsedQueryString.next, removeKey(parsedQueryString, "next"));
                state.nextUrl = next;
                state.queryMessage = parsedQueryString.message;
            }
            else if (parsedQueryString.next || parsedQueryString.message) {
                const { next, message } = parsedQueryString;
                state.nextUrl = next;
                state.queryMessage = message;
            }
            else {
                const { next, message } = location.state || { next: undefined, message: undefined };
                state.nextUrl = next;
                state.queryMessage = message;
            }
            state.initialized = true;
        },
    },
});
