import URI from "urijs";
import { defineMessages } from "@mnemonic/intl";
import { commonMessages } from "../../../commonMessages";
const messages = defineMessages({
    createApikey: { id: "apikey.create", defaultMessage: "Create apikey" },
    renewApikey: { id: "apikey.renew", defaultMessage: "Renew apikey" },
    enrollPublicKey: { id: "publickey.enroll", defaultMessage: "Enroll public key" },
    totpInitialize: { id: "totp.initialize", defaultMessage: "Initialize TOTP" },
    unknownOperation: { id: "unknown.operation", defaultMessage: "Unknown operation" },
});
const operationToMessage = (operation) => {
    switch (operation) {
        case "apikey.create":
            return messages.createApikey;
        case "apikey.renew":
            return messages.renewApikey;
        case "password.change":
            return commonMessages.ChangePassword;
        case "publickey.enroll":
            return messages.enrollPublicKey;
        case "totp.initialize":
            return messages.totpInitialize;
        default:
            return messages.unknownOperation;
    }
};
export const getOperationTitle = (operation, intl) => {
    return operationToMessage(operation).id === "unknown.operation"
        ? `${intl.formatMessage(operationToMessage(operation))}: ${operation}`
        : intl.formatMessage(operationToMessage(operation));
};
export const getUserAuthSearch = (href) => URI.parseQuery(new URI(href).search());
