import React from "react";
import { NavLink } from "react-router-dom";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
export const EnrollmentStepper = ({ steps, activeStep }) => {
    if (activeStep === -1)
        return null;
    return (<>
      <Typography variant="h5">
        <FormattedMessage id="login.verify.title" defaultMessage="Verify user and set password"/>
      </Typography>
      <Link component={NavLink} to={{ pathname: `/enroll` }}>
        <FormattedMessage id="login.verify.verified.already" defaultMessage="Already verified? Log in"/>
      </Link>
      <Stepper activeStep={activeStep} alternativeLabel sx={(theme) => ({ my: theme.spacing(5) })}>
        {steps.map((step) => (<Step key={step.key}>
            <StepLabel>{step.label}</StepLabel>
          </Step>))}
      </Stepper>
    </>);
};
