import { fetchSession } from "@mne-authentication/api";
import HttpError from "./Errors/HttpError";
export default async function initializeSession(target) {
    const { domain, apiUrl } = target;
    const query = await fetchSession({ domain }, { apiUrl });
    if (query?.response?.ok) {
        return query.json;
    }
    else if (query?.response?.status === 401) {
        return null;
    }
    throw new HttpError(query.response.statusText, query);
}
