import { defineMessages } from "@mnemonic/intl";
export const commonMessages = defineMessages({
    AD3: { id: "AD3", defaultMessage: "AD3" },
    AuthenticationFailed: { id: "AuthenticationFailed", defaultMessage: "Authentication failed" },
    Authorize: { id: "Authorize", defaultMessage: "Authorize" },
    Cancel: { id: "Cancel", defaultMessage: "Cancel" },
    ChangePassword: { id: "ChangePassword", defaultMessage: "Change password" },
    ContactUs: { id: "ContactUs", defaultMessage: "Contact us" },
    Customers: { id: "Customers", defaultMessage: "Customers" },
    EnrollmentCode: { id: "EnrollmentCode", defaultMessage: "Enrollment code" },
    OPENID: { id: "Openid", defaultMessage: "Single sign on" },
    PASSWORD: { id: "Password", defaultMessage: "Password" },
    SIGNATURE: { id: "Signature", defaultMessage: "Security Key" },
    SMS: { id: "SMS", defaultMessage: "SMS" },
    SetPassword: { id: "SetPassword", defaultMessage: "Set password" },
    TOTP: { id: "TOTP", defaultMessage: "Time-based One Time Password (TOTP)" },
    TokenCode: { id: "TokenCode", defaultMessage: "Token code" },
    UnknownError: { id: "UnknownError", defaultMessage: "Unknown error" },
    VerificationCode: { id: "VerificationCode", defaultMessage: "Verification code" },
});
