import React, { useContext } from "react";
import SessionContext from "./Session/SessionContext";
import { isAuthenticated } from "./Utils";
const Unauthenticated = (props) => {
    const { required = true, children: origChildren, component, componentProps } = props;
    const sessionCtx = useContext(SessionContext);
    const { loading, session } = sessionCtx;
    // While the session is loading this component renders nothing.
    // If you wish to render a component during loading use withSession()
    // or the Authenticating component
    if (!loading && (!required || (required && !isAuthenticated(session)))) {
        /**
         * Render prioritization.
         * 1. Function-as-child
         * 2. props.component
         * 3. Whatever was passed as props.children
         */
        if (typeof origChildren === "function") {
            const renamedChildFunctionToMakeTypeScriptUnderstand = origChildren;
            return <React.Fragment>{renamedChildFunctionToMakeTypeScriptUnderstand(session, sessionCtx)}</React.Fragment>;
        }
        if (component) {
            return React.createElement(component, { session, sessionCtx, ...componentProps });
        }
        return <React.Fragment>{origChildren}</React.Fragment>;
    }
    return null;
};
export default Unauthenticated;
