import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { PageLayout, RemoveSplash, Section } from "@mnemonic/mui5";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { authenticateOauth } from "@mne-authentication/api";
import { SessionContext } from "@mne-authentication/session2";
import { LoginBoxCallout } from "../Components/Login/Layout";
import { buildURLBasedOnSearchValues, concatURLStringAndRedirect, getAuthorizeParameters, } from "./UserAuthorization/utils/oAuthHandlerUtils";
const URL_PREFIX = "/spa/authentication";
function OAuthHandler() {
    const authorizeParameters = getAuthorizeParameters(window.location.href);
    const apiUrl = ArgusUtils.getApiUrl();
    const { session } = useContext(SessionContext);
    const [errorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const opts = {
        apiUrl,
        sessionKey: "",
    };
    if (session) {
        opts.sessionKey = session.info.sessionKey;
    }
    const parametersForAuthentication = {
        clientID: authorizeParameters.client_id,
        nonce: authorizeParameters.nonce,
        scope: authorizeParameters.scope?.split(" "),
        redirectURI: authorizeParameters.redirect_uri,
        responseType: authorizeParameters.response_type,
        state: authorizeParameters.state,
    };
    useEffect(() => {
        authenticateOauth(parametersForAuthentication, opts).then(({ json, response }) => {
            if (response.ok) {
                return (window.location.href = buildURLBasedOnSearchValues(json?.data, parametersForAuthentication?.responseType));
            }
            if (response.status === 401) {
                const loginPageUrl = `${URL_PREFIX}/login?next=${URL_PREFIX}/oauth/authorize?`;
                // Redirect to login page with nextUrl pointing back with original request
                return concatURLStringAndRedirect(loginPageUrl, authorizeParameters);
            }
            if (!response.ok) {
                // Show error page
                setErrorMessage(`${response.status} ${response.statusText} ${response.headers.get("Argus-Request-ID") || ""} ${json.messages[0].timestamp || ""}`);
                return setErrorDialog(true);
            }
        });
    }, []);
    return (<PageLayout PageHeaderProps={{
            content: (<Typography variant="h6">
            <FormattedMessage id="login.authentication" defaultMessage="Authentication"/>
          </Typography>),
        }}>
      {errorDialog && (<Section sx={({ spacing }) => ({ maxWidth: spacing(80), minHeight: spacing(20), m: "auto" })}>
          <RemoveSplash />
          <Section.Content>
            <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>
          </Section.Content>
        </Section>)}
    </PageLayout>);
}
export default OAuthHandler;
