import React, { useCallback } from "react";
import Logger from "@mnemonic/logging";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { hasPermission } from "@mne-authentication/api";
import { Session } from "@mne-authentication/session2";
const ApplicationSession = ({ children, session }) => {
    const checkPermission = useCallback(({ customerId, name }) => {
        if (!session)
            return Promise.reject("No session available");
        if (!session?.functions?.length) {
            Logger.getInstance().logError("Check permission called, but no functions defined", {
                session: JSON.stringify(session),
            });
            return Promise.resolve(false);
        }
        if (!customerId) {
            return Promise.resolve(session?.functions?.some((func) => func.name === name));
        }
        return hasPermission(name, customerId, { apiUrl: ArgusUtils.getApiUrl() }).then(({ response }) => Boolean(response.ok));
    }, [session]);
    return <Session {...{ checkPermission, loading: false, session }}>{children}</Session>;
};
export default ApplicationSession;
