import { createTheme, useTheme } from "@mui/material/styles";
import { createMnemonicDarkTheme } from "@mnemonic/mui5/styles";
export const useLeftSidebarTheme = () => {
    // Get the theme that is defined higher than the menu in the render tree.
    const parentTheme = useTheme();
    // The LeftSidebar is always dark.
    const darkTheme = createMnemonicDarkTheme();
    const theme = createTheme(darkTheme, {
        palette: {
            // The dark theme uses a #3DB6B9 (cyan/blue) as primary. In the menu we want to use white.
            primary: { main: parentTheme.palette.common.white },
            // Set the orange/secondary color in the menu to match the orange/secondary color in the page.
            secondary: parentTheme.palette.secondary,
        },
    });
    return { theme, parentTheme };
};
