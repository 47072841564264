import { QueryController, createCsrfToken, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (opts) => {
    const { apiUrl, apikey, sessionKey, ...restOpts } = opts;
    const url = new AuthenticationURI().setApiUrl(apiUrl).setSegment("logout");
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        "Argus-CSRF-Token": !apikey ? createCsrfToken(sessionKey, url.toString()) : false,
    });
    let localRequestOpts = {
        headers,
        method: "DELETE",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(url), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function logout(opts) {
    return await fetchJsonQuery(createRequest(opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default logout;
