import { QueryController, createGetRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = (queryParams, opts) => {
    const { apiUrl } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "oauth", "client"], queryParams).toString();
    return new Request(uri, createGetRequestOpts(opts));
};
async function fetchOauthClients(queryParams, opts) {
    return await fetchJsonQuery(createRequest(queryParams, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default fetchOauthClients;
