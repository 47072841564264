import React, { useContext, useEffect } from "react";
import { useHref, useLocation } from "react-router-dom";
import { SplashLoading } from "@mnemonic/mui5";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { logout } from "@mne-authentication/api";
import { SessionContext } from "@mne-authentication/session2";
function LogoutComp() {
    const location = useLocation();
    const nextPath = useHref({ ...location, pathname: "/login" });
    const { session } = useContext(SessionContext);
    useEffect(() => {
        //eslint-disable-next-line
        onLogout(session)
            .then(() => {
            //eslint-disable-next-line
            onLogoutSuccess();
        })
            .catch((e) => this.setState(() => {
            throw e;
        })); // Force ErrorBoundary
    }, []);
    const onLogoutSuccess = () => {
        // Force a full page refetch to clear all stale data (menu & sessionCtx)
        window.location.replace(nextPath);
    };
    /**
     * Logout the user
     * If the user is not logged in, treat it as a successful logout
     */
    const onLogout = (sessionParam) => {
        if (!sessionParam) {
            return Promise.resolve();
        }
        const ignore401ErrorHandler = (e) => {
            if (e.raw && e.raw.status === 401)
                return Promise.resolve();
            throw e;
        };
        const apiUrl = ArgusUtils.getApiUrl();
        const { sessionKey } = session.info;
        /* Hack to resovle with void */
        return new Promise((resolve, reject) => {
            Promise.all([logout({ apiUrl, sessionKey }).catch(ignore401ErrorHandler)]).then(() => resolve(), reject);
        });
    };
    // Don't remove the splash screen if visible, instead display status message.
    return <SplashLoading text="Logging out"/>;
}
export default LogoutComp;
