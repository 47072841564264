import { QueryController, createCsrfToken, createPostRequestOpts, createUrl, fetchJsonQuery } from "@mne-core/api";
export const createRequest = (criteria, opts) => {
    const { apiUrl, sessionKey } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "oauth", "authorize"]).toString();
    return new Request(uri, createPostRequestOpts(JSON.stringify(criteria), { ...opts, csrfToken: createCsrfToken(sessionKey, uri) }));
};
async function authenticateOauth(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authenticateOauth;
