import { QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (criteria, opts) => {
    const { apiUrl, apikey, ...restOpts } = opts;
    const uri = new AuthenticationURI()
        .setApiUrl(apiUrl)
        .setSegment(["signature", "webauthn", "authentication", "challenge"])
        .build();
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
        "Content-Type": "application/json",
    });
    let localRequestOpts = {
        body: JSON.stringify(criteria),
        headers,
        method: "POST",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function prepareWebauthnAuthentication(params, opts) {
    return await fetchJsonQuery(createRequest(params, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default prepareWebauthnAuthentication;
