import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
const getStyles = (theme) => ({
    iconWrapper: {
        marginTop: "10px",
        minHeight: theme.spacing(8),
        minWidth: theme.spacing(8),
        textAlign: "center",
        verticalAlign: "middle",
    },
    icon: {
        height: theme.spacing(8),
        width: theme.spacing(8),
        padding: theme.spacing(1),
    },
});
const BaseButton = ({ Icon, title, description, onClick }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    return (<ListItemButton disableRipple onClick={onClick}>
      <div style={{ ...styles.iconWrapper }}>
        <Icon style={{ ...styles.icon }}/>
      </div>
      <ListItemText primary={title} secondary={description}/>
    </ListItemButton>);
};
export default BaseButton;
