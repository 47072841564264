import * as React from "react";
import Grid from "@mui/material/Grid";
import { defineMessages } from "@mnemonic/intl";
import CardError from "@mnemonic/mui5/CardError";
import CardTitleError from "@mnemonic/mui5/CardTitleError";
import { ErrorIcon } from "@mnemonic/mui5/icons";
const messages = defineMessages({
    subtitle: {
        defaultMessage: "Query parameters undefined",
        description: "Text for error card on User Authorization page",
        id: "mne-authentication.userauthorization.undefined.parameters",
    },
});
const IconComponent = ErrorIcon;
const ErrorCardQueryParameters = ({ intl }) => {
    const { formatMessage } = intl;
    return (<Grid container justifyContent="center" alignItems="center" spacing={1}>
      <div style={{ width: "30%" }}>
        <CardError>
          <CardTitleError Icon={IconComponent} colorIcon={"action"} subtitle={formatMessage(messages.subtitle)}/>
          <br />
        </CardError>
      </div>
    </Grid>);
};
export default ErrorCardQueryParameters;
