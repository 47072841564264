import React from "react";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { RemoveSplash, Section } from "@mnemonic/mui5";
import { PageLayout } from "@mnemonic/mui5/src/PageLayout";
const APPLE_STORE_URL = "https://apps.apple.com/us/app/argus-for-mobile/id1535606773";
const ANDROID_STORE_URL = "https://play.google.com/store/apps/details?id=com.mnemonic.argus_mobile";
export default () => {
    if (navigator?.userAgent?.match(/android/i)) {
        window.location.replace(ANDROID_STORE_URL);
        return null;
    }
    else if (navigator?.userAgent?.match(/iphone|ipad/i)) {
        window.location.replace(APPLE_STORE_URL);
        return null;
    }
    else {
        return (<PageLayout PageHeaderProps={{
                content: (<Typography variant="h6">
              <FormattedMessage id="login.authentication" defaultMessage="Authentication"/>
            </Typography>),
            }} ServiceNavigationProps={{ isEnabled: false }} SidebarLeftProps={{ isEnabled: false }} SidebarRightProps={{ isEnabled: false }}>
        <RemoveSplash />
        <Section sx={({ spacing }) => ({ p: spacing(4), maxWidth: spacing(100), m: "auto" })}>
          <Section.Content>
            <Typography variant="body2">
              <FormattedMessage id="redirectmobile.text" defaultMessage="If you are not automatically redirected click the correct link below:"/>
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AppleIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link href={APPLE_STORE_URL}>
                    <FormattedMessage id="redirectmobile.applestore" defaultMessage="Apple store"/>
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AndroidIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link href={ANDROID_STORE_URL}>
                    <FormattedMessage id="redirectmobile.androidstore" defaultMessage="Android store"/>
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Section.Content>
        </Section>
      </PageLayout>);
    }
};
