import React from "react";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import { FormattedMessage } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import Disclaimer from "../../Components/GDPRDisclaimer";
import { TextField } from "../../Components/MuiFinalForm";
import { TokenProblems } from "../../Components/TokenProblems";
import { useAuthenticateSMSMutation } from "../../Redux/Service/authenticateApi";
import { useTypedDispatch } from "../../Redux/createStore";
import { globalSlice } from "../../Redux/globalSlice";
import { StyledDisclaimerWrapper, StyledSingleFieldWrapper } from "./StyledComponets";
import { saveLoginDetails } from "./utils/saveLoginDetails";
export const LoginSMSValidateToken = ({ smsStepState }) => {
    const { cookie, userName } = smsStepState;
    const { domain } = ArgusUtils.getTargetMeta();
    const [authenticateSms, { isLoading, error }] = useAuthenticateSMSMutation();
    const dispatch = useTypedDispatch();
    const errorMessage = extractErrorMessage(error);
    return (<Form initialValues={{ passcode: null }} onSubmit={(formValues) => authenticateSms({
            criteria: {
                ...formValues,
                domain,
                cookie,
                userName,
                mode: "VERIFICATION",
            },
        })
            .unwrap()
            ?.then((payload) => {
            const isSuccess = saveLoginDetails({ username: userName, method: "sms", payload });
            dispatch(globalSlice.actions.setLoginSuccess(isSuccess));
        })
            ?.catch((error) => {
            console.error(error);
        })} render={({ handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
            <StyledSingleFieldWrapper>
              <TextField name="passcode" autoFocus autoComplete="off" fullWidth label={<FormattedMessage id="login.smsCode" defaultMessage="SMS code"/>} error={Boolean(errorMessage)} helperText={errorMessage ? (errorMessage) : (<FormattedMessage id="login.helper.sms.token" defaultMessage="Enter the code received on your phone"/>)}/>
            </StyledSingleFieldWrapper>
            {cookie && <TokenProblems cookie={cookie}/>}
            <StyledDisclaimerWrapper>
              <Disclaimer />
              <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                <FormattedMessage id="login.verify" defaultMessage="Verify"/>
              </Button>
            </StyledDisclaimerWrapper>
          </form>);
        }}/>);
};
