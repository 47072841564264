import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { authorizeLdap, authorizePassword, authorizeSMS, authorizeTotp, authorizeWebauthn, prepareWebauthnAuthorization, requestAuthorizationTokenSMS, requestRedirectURIOpenIDAuthorization, } from "@mne-authentication/api";
import { getSessionKey } from "../utils/getSessionKey";
import { baseApi } from "./baseApi";
const authorizeApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        /* Queries */
        requestAuthorizationTokenSMS: build.query({
            queryFn: ({ metaOpts }, api) => handleResponse(requestAuthorizationTokenSMS({ sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        requestRedirectURIOpenIDAuthorization: build.query({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(requestRedirectURIOpenIDAuthorization(criteria, {
                sessionKey: getSessionKey(api),
                apiUrl: ArgusUtils.getApiUrl(),
            }), metaOpts),
        }),
        prepareWebauthnAuthorization: build.query({
            queryFn: ({ metaOpts }, api) => handleResponse(prepareWebauthnAuthorization({ sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        /* Mutations */
        authorizeSMS: build.mutation({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(authorizeSMS(criteria, { sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthorizationData"],
        }),
        authorizeLdap: build.mutation({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(authorizeLdap(criteria, { sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthorizationData"],
        }),
        authorizePassword: build.mutation({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(authorizePassword(criteria, { sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthorizationData"],
        }),
        authorizeTotp: build.mutation({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(authorizeTotp(criteria, { sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthorizationData"],
        }),
        authorizeWebauthn: build.mutation({
            queryFn: ({ criteria, metaOpts }, api) => handleResponse(authorizeWebauthn(criteria, { sessionKey: getSessionKey(api), apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthorizationData"],
        }),
    }),
});
export const { useAuthorizeSMSMutation, useAuthorizeLdapMutation, useRequestAuthorizationTokenSMSQuery, useRequestRedirectURIOpenIDAuthorizationQuery, useAuthorizePasswordMutation, useAuthorizeTotpMutation, useAuthorizeWebauthnMutation, usePrepareWebauthnAuthorizationQuery, } = authorizeApi;
export default authorizeApi;
