import { createApi } from "@reduxjs/toolkit/query/react";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { fetchArgusQuery } from "@mnemonic/redux-utils";
const tagTypes = ["AuthenticationData", "AuthorizationData"];
export const baseApi = createApi({
    reducerPath: "api",
    baseQuery: fetchArgusQuery({
        baseUrl: ArgusUtils.getApiUrl(),
    }),
    tagTypes,
    endpoints: () => ({}),
});
export default baseApi;
