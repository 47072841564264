import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createArgusTheme } from "@mnemonic/mui5";
import CoreMui5Provider from "@mnemonic/mui5/CoreMui5Provider";
import { createMnemonicDarkTheme, createMnemonicLightTheme } from "@mnemonic/mui5/src/styles";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
const determineThemeWithoutSession = ({ themeModeFromLocalStorage, browserPref }) => {
    const { metaInfo } = ArgusUtils.getTargetMeta() || {};
    if (metaInfo?.theme === "nfcert")
        return false;
    if (!themeModeFromLocalStorage || themeModeFromLocalStorage === "auto") {
        return browserPref;
    }
    return themeModeFromLocalStorage === "dark";
};
export const ThemeProvider = ({ children, domain, locale, session }) => {
    const browserPref = useMediaQuery("(prefers-color-scheme: dark)");
    const themeModeFromLocalStorage = localStorage?.getItem("ui.themeMode");
    const shouldShowDarkTheme = determineThemeWithoutSession({ browserPref, themeModeFromLocalStorage });
    return (<CoreMui5Provider domain={domain} locale={locale} theme={createArgusTheme(session, (createTheme, localizationOpts) => {
            /* Pass a empty object if there are no local overrides */
            const themeOptions = {};
            /* If we have a session we use the createArgusTheme to determine the theme*/
            if (session) {
                return createTheme(themeOptions, localizationOpts);
            }
            /* If session is not defined we use shouldShowDarkTheme to deterine the theme */
            if (shouldShowDarkTheme) {
                return createMnemonicDarkTheme(themeOptions, localizationOpts);
            }
            else {
                return createMnemonicLightTheme(themeOptions, localizationOpts);
            }
        })}>
      {children}
    </CoreMui5Provider>);
};
