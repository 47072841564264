import React, { useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import { FilterBox, FilterBoxActions, FilterBoxLabel } from "@mnemonic/mui5";
import AutocompleteOptionCustomer from "@mnemonic/mui5/AutocompleteOptionCustomer";
import ChipComponent from "./Chip";
const getStyles = (theme) => ({
    checkboxes: {
        marginTop: theme.spacing(2),
    },
    paper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
});
const Customers = (props) => {
    const { error, loading, options, onChange, onCheckboxChange } = props;
    const theme = useTheme();
    const styles = getStyles(theme);
    const [customers, setCustomers] = useState([]);
    const [isAutocompleterOpen, setAutocompleterOpen] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        showCustomerApplications: false,
        showNonCustomerApplications: false,
    });
    const { showCustomerApplications, showNonCustomerApplications } = checkboxState;
    // Filterbox state
    const [open, setOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const handleFilterBoxClick = (event) => {
        setAnchorElement(event.currentTarget);
        setOpen(true);
    };
    const handleCloseFilterBox = () => {
        setOpen(false);
        setAnchorElement(null);
        setSelectedCustomers(customers);
    };
    const onApplyFilter = () => {
        setOpen(false);
        setCustomers(selectedCustomers);
        onChange(selectedCustomers.map((customer) => customer.id));
        onCheckboxChange(checkboxState);
    };
    const handleCheckboxChange = (event) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    };
    const formatValue = (value) => {
        if (value && value.length === 1) {
            return value[0].name;
        }
        return value;
    };
    const isActive = Boolean(formatValue(customers) &&
        Boolean(!Array.isArray(formatValue(customers)) ||
            formatValue(customers).length ||
            showCustomerApplications != showNonCustomerApplications));
    const stringify = (item) => item.name;
    return (<FilterBox sx={{ ...styles.paper }} state={isActive ? (error ? "error" : "active") : "normal"} onClose={() => handleCloseFilterBox()} anchorEl={anchorElement} anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
        }} transformOrigin={{
            horizontal: "center",
            vertical: "top",
        }} open={open} onClick={(event) => handleFilterBoxClick(event)} label={<FormattedMessage id="Customers" defaultMessage="Customers"/>} LabelComponentProps={{
            active: isActive,
            badge: (customers.length > 1 && customers.length) ||
                (showCustomerApplications != showNonCustomerApplications && customers.length !== 1 && 1),
            secondary: customers.length === 1 && formatValue(customers),
        }} LabelComponent={FilterBoxLabel}>
      <Autocomplete value={selectedCustomers || []} onChange={(_, newValue) => setSelectedCustomers(newValue)} loading={loading} options={options} getOptionLabel={stringify} filterOptions={createFilterOptions({
            stringify: (option) => [option?.name, option?.shortName].filter(Boolean).join(" "),
        })} open={isAutocompleterOpen} onOpen={() => setAutocompleterOpen(true)} onClose={() => setAutocompleterOpen(false)} renderTags={(values, getTagProps) => values.map((item, index) => <ChipComponent key={index} item={item} {...getTagProps({ index })}/>)} renderOption={(props, option) => <AutocompleteOptionCustomer key={option.id} customer={option} {...props}/>} renderInput={(renderProps) => (<TextField {...renderProps} variant="outlined" error={Boolean(error)} label={<FormattedMessage id="Customers" defaultMessage="Customers"/>} helperText={error || undefined} inputProps={{
                ...renderProps.inputProps,
                autoComplete: "off",
            }} style={{
                minWidth: 200,
            }} InputProps={{
                ...renderProps.InputProps,
                endAdornment: (<React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20}/> : null}
                  {renderProps.InputProps.endAdornment}
                </React.Fragment>),
            }} fullWidth/>)} autoHighlight multiple/>
      <FormControl sx={{ ...styles.checkboxes }}>
        <FormLabel>
          <FormattedMessage id="client.overview.apps" defaultMessage="Applications"/>
        </FormLabel>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={showCustomerApplications} onChange={handleCheckboxChange} name="showCustomerApplications"/>} label={<FormattedMessage id="client.overview.customer.specific" defaultMessage="Customer specific"/>}/>
          <FormControlLabel control={<Checkbox checked={showNonCustomerApplications} onChange={handleCheckboxChange} name="showNonCustomerApplications"/>} label={<FormattedMessage id="client.overview.non.customer.specific" defaultMessage="Non customer specific"/>} style={{ whiteSpace: "nowrap" }}/>
        </FormGroup>
      </FormControl>
      <FilterBoxActions>
        <Button onClick={() => handleCloseFilterBox()}>
          <FormattedMessage id="Cancel"/>
        </Button>
        <Button onClick={() => onApplyFilter()}>
          <FormattedMessage id="apply" defaultMessage="Apply"/>
        </Button>
      </FilterBoxActions>
    </FilterBox>);
};
export default Customers;
