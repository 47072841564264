import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
export const AuthenticationMethodsList = ({ methods, handleClick = () => { } }) => (<List>
    {methods?.map(({ key, primary, to, secondary, icon }, index) => (<ListItem key={key} onClick={() => handleClick(key)} divider={index !== methods.length - 1} sx={({ spacing }) => ({ px: spacing(2), py: spacing(1), minHeight: spacing(10) })}>
        <Link to={to} style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center" }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} secondary={secondary}/>
        </Link>
      </ListItem>))}
  </List>);
