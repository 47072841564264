import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "@mnemonic/intl";
import { validateEmptyField } from "../Pages/Login/utils/validate";
import { TextField } from "./MuiFinalForm";
const messages = defineMessages({
    ad3: { id: "login.ad3.password", defaultMessage: "Your AD3 password" },
    static: { id: "login.static.password", defaultMessage: "Your static password" },
});
export const PasswordField = ({ autoFocus = false, method, name, }) => {
    const { formatMessage } = useIntl();
    const helperTextMessage = messages[method];
    return (<TextField autoComplete="current-password" autoFocus={autoFocus} fullWidth helperText={formatMessage(helperTextMessage)} label={<FormattedMessage id="Password" defaultMessage="Password"/>} name={name} type="password" validate={(value) => validateEmptyField(value)}/>);
};
