import Logger, { flatten, serializeLocation } from "@mnemonic/logging";
import PageAccessDenied from "@mnemonic/mui5/PageAccessDenied";
import { permissionRequiredRender } from "@mnemonic/router";
import { authenticationRequired, routeRedirect } from "@mnemonic/router";
import { AuthenticatePage } from "../Pages/Authenticate/AuthenticatePage";
import ChangePasswordPage from "../Pages/ChangePasswordPage";
import ClientOverviewPage from "../Pages/ClientOverview";
import { LoginLdap } from "../Pages/Login/LoginLdap";
import { LoginComponentMenu } from "../Pages/Login/LoginMenu";
import { LoginOpenID } from "../Pages/Login/LoginOpenID";
import { LoginPassword } from "../Pages/Login/LoginPassword";
import { LoginSms } from "../Pages/Login/LoginSms";
import { LoginTotp } from "../Pages/Login/LoginTotp";
import { LoginWebAuthn } from "../Pages/Login/LoginWebAuthn";
import LogoutPage from "../Pages/Logout";
import OAuthHandler from "../Pages/OAuthHandler";
import OpenIDHandler from "../Pages/OpenIDHandler";
import RedirectMobile from "../Pages/RedirectMobile";
import { AuthorizationPage } from "../Pages/UserAuthorization/AuthorizationPage";
import { VerifyUsernamePassword } from "../Pages/VerifyUsernamePassword";
import PageNotFound from "./PageNotFound";
const logRoute = () => () => {
    Logger.getInstance()?.logInfo("RouteChange", {
        ...flatten(serializeLocation(window.location), { prefix: "location" }),
    });
    return null;
};
export const routeDefinitions = [
    { path: "/", interceptors: [routeRedirect("login")] },
    { path: "enroll", component: AuthenticatePage },
    { path: "enroll/activate", component: VerifyUsernamePassword },
    { path: "login", component: LoginComponentMenu },
    { path: "login/ldap/*", component: LoginLdap },
    { path: "login/password", component: LoginPassword },
    { path: "login/sms", component: LoginSms },
    // ARGUS-38521 - Infra uses this in an E2E Test, be aware
    { path: "login/totp", component: LoginTotp },
    { path: "login/openid", component: LoginOpenID },
    { path: "login/webauthn", component: LoginWebAuthn },
    // ARGUS-38521 - Infra uses this in an E2E Test, be aware
    { path: "logout", component: LogoutPage },
    { path: "changepassword", component: ChangePasswordPage, interceptors: [logRoute()] },
    { path: "oauth/authorize", component: OAuthHandler },
    {
        path: "oauth/clients",
        component: ClientOverviewPage,
        interceptors: [
            authenticationRequired(),
            permissionRequiredRender("viewOAuthClients", PageAccessDenied),
            logRoute(),
        ],
    },
    { path: "openid/provider/:providerId/authenticate", component: OpenIDHandler, interceptors: [logRoute()] },
    { path: "userauthorization", component: AuthorizationPage, interceptors: [authenticationRequired(), logRoute()] },
    { path: "redirect/mobile-app", component: RedirectMobile },
    /* "Page not found" page for the whole of argus (https://stash.mnemonic.no/projects/INFRA/repos/puppet/browse/modules/argus_frontend/templates/spa-ssl.conf.erb#95)*/
    { path: "404", component: PageNotFound, interceptors: [authenticationRequired(), logRoute()] },
    { path: "*", component: PageNotFound, interceptors: [logRoute()] },
];
