import URI from "urijs";
const parseHash = (hash) => {
    if (!hash)
        return {};
    const parsedHash = new URLSearchParams(hash.substring(1));
    return {
        idToken: parsedHash.get("id_token"),
        state: parsedHash.get("state"),
        error: parsedHash.get("error"),
        errorDescription: parsedHash.get("error_description"),
    };
};
const parseQuery = (query) => {
    if (!query)
        return {};
    const queries = URI.parseQuery(query);
    return {
        code: queries.code,
        state: queries.state,
    };
};
/**
 * Extracts authentication response parameters from the current URL provided by Open Id.
 * It parses both the URL's hash and query parameters to find authentication-related information.
 *
 * The function handles two main scenarios:
 * 1. Successful authentication with a code or idToken and state.
 * 2. Error situations where an error and errorDescription are provided in the URL.
 
 *
 * @returns {Object} An object representing the authentication response. The structure of the object is as follows:
 * - {string} type: Indicates the type of response, either 'submit' for successful authentication or 'error' for errors.
 * - {string} [state]: The state parameter from the URL, if available.
 * - {string} [code]: The authorization code from the URL, if available.
 * - {string} [idToken]: The ID token from the URL, if available.
 * - {string} [error]: The error parameter from the URL, if available.
 * - {string} [errorDescription]: The error description from the URL, if available.
 *
 */
export const extractAuthResponse = () => {
    const hashParams = parseHash(window?.location?.hash);
    const queryParams = parseQuery(window?.location?.search);
    const params = { ...hashParams, ...queryParams };
    if (params?.error || params?.errorDescription) {
        return {
            type: "error",
            error: params.error || null,
            errorDescription: params?.errorDescription,
        };
    }
    return {
        type: "submit",
        state: params.state,
        code: params.code,
        idToken: params.idToken,
    };
};
