const isSpaPath = (path) => {
    //ensure the path is a path and not a URL
    let normalisedPath;
    try {
        const { pathname } = new URL(path);
        normalisedPath = pathname;
    }
    catch {
        normalisedPath = normalizePath(path);
    }
    if (normalisedPath.startsWith("/spa/")) {
        return true;
    }
    else {
        return false;
    }
};
const normalizePath = (path) => {
    let cleanPath;
    if (path.includes("?") || path.includes("#")) {
        let firstOccuranceIndex = 0;
        if (path.includes("?") && path.includes("#")) {
            firstOccuranceIndex = Math.min(path.indexOf("?"), path.indexOf("#"));
        }
        else if (path.includes("#")) {
            firstOccuranceIndex = path.indexOf("#");
        }
        else {
            firstOccuranceIndex = path.indexOf("?");
        }
        cleanPath = path
            .substring(0, firstOccuranceIndex)
            .split("/")
            .filter((segment) => Boolean(segment))
            .join("/");
        const urlParams = path.substring(firstOccuranceIndex);
        return `/${cleanPath + urlParams}`;
    }
    cleanPath = path
        .split("/")
        .filter((segment) => Boolean(segment))
        .join("/");
    return `/${cleanPath}`;
};
export { normalizePath, isSpaPath };
