import React, { useContext, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import { SessionContext } from "@mne-authentication/session2";
function ChipWithPopover(props) {
    const [open, setOpen] = useState(false);
    const element = useRef(null);
    const { session } = useContext(SessionContext);
    const { domain } = session.user;
    return (<>
      <Popover {...{ open }} onClose={(e) => {
            e.stopPropagation();
            setOpen(false);
        }} anchorEl={element.current} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }}>
        <ListItem>
          <ListItemText primary={props.item.name} secondary={
        // Only show the domain if it's not the same as the current users
        [domain.name === props.item.domain.name ? undefined : props.item.domain.name, props.item.shortName]
            .filter(Boolean)
            .join("/")}/>
        </ListItem>
      </Popover>
      <span ref={(c) => {
            element.current = c;
        }} onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
        }}>
        <Chip {...props} label={props.item.name} clickable/>
      </span>
    </>);
}
export default ChipWithPopover;
