import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
const RESOURCES_PACKAGE_VERSION = "1.10.0";
/**
 * Retrieves a static resource URL from the `/pages/resources` page.
 *
 * This function constructs a URL based on a static package version and
 * the provided resource path.
 *
 * @param {string} path - The relative path of the resource within the `/resources` page.
 *
 * @returns {string} The fully constructed static URL pointing to the resource.
 *
 * @example
 * ```ts
 * // Get the URL for a logo image
 * const logoUrl = getStaticResourceSrc('images/logo.png');
 * console.log(logoUrl); // Outputs: <static-url>/resources/versions/1.10.1/images/logo.png
 * ```
 */
export const getStaticResourceSrc = (path) => ArgusUtils.toStaticUrl(`resources/versions/${RESOURCES_PACKAGE_VERSION}/${path}`);
