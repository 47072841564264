import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { PageLayout, RemoveSplash, Section } from "@mnemonic/mui5";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import * as UrlUtils from "@mnemonic/pure-utils/UrlUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { authorizeOpenID } from "@mne-authentication/api";
import { SessionContext } from "@mne-authentication/session2";
import { LoginBoxCallout } from "../Components/Login/Layout";
import { useSubmitOpenIDAuthCodeByProviderIDMutation } from "../Redux/Service/openIdApi";
import { setOpenIdFlowSessionStorage } from "../utils/openIdFlowSessionStorage";
import { extractAuthResponse } from "../utils/parseUrl";
import redirectUser from "../utils/redirectUser";
import { redirectWithToken } from "./UserAuthorization/utils/redirectWithToken";
function OpenIDHandler() {
    const authResponse = extractAuthResponse();
    const [submitOpenIDAuthCodeByProviderID, { isLoading, error, data }] = useSubmitOpenIDAuthCodeByProviderIDMutation();
    const errorMessage = extractErrorMessage(error);
    const params = useParams();
    const [errorData, setErrorData] = useState({ error: false, message: null, responseCode: null });
    const apiUrl = ArgusUtils.getApiUrl();
    const { session } = useContext(SessionContext);
    const submitAuthCode = ({ nextUrl }) => {
        submitOpenIDAuthCodeByProviderID({
            criteria: {
                authorizationCode: authResponse?.code,
                flow: authResponse?.code ? "authorizationCode" : "idToken",
                idToken: authResponse?.idToken,
                provider: params?.providerId,
            },
        })
            .unwrap()
            .then(({ responseCode }) => {
            if (responseCode === 201) {
                redirectUser({ changePasswordNeeded: false, nextUrl });
            }
            if (responseCode === 221) {
                redirectUser({ changePasswordNeeded: true, nextUrl });
            }
        })
            .catch((error) => console.log(error));
    };
    const authorize = ({ nextUrl, stateID }) => {
        authorizeOpenID({ idToken: authResponse.idToken, stateID }, { apiUrl, sessionKey: session.info.sessionKey }).then(({ response, json }) => {
            if (response.status === 200) {
                redirectWithToken({
                    nextURI: nextUrl,
                    payload: { responseCode: response.status, data: { authorization: json.data.authorization } },
                });
            }
            if ([401, 403, 404, 412].includes(response.status)) {
                setErrorData({
                    error: true,
                    message: json.messages[0]?.message ?? response.statusText,
                    responseCode: response.status,
                });
            }
            if (response.status >= 500) {
                setErrorData({
                    error: true,
                    message: response.statusText,
                    responseCode: response.status,
                });
            }
        });
    };
    useEffect(() => {
        setOpenIdFlowSessionStorage("providerResponseState", authResponse?.state);
        if (authResponse.type === "error") {
            setErrorData({ ...errorData, error: true, message: authResponse.errorDescription });
        }
        else if (authResponse.type === "submit") {
            try {
                // Submit id token and redirect to nextURI if authenticated
                const { type, nextURI, nonce, id } = UrlUtils.decodeUrlCriteria(decodeURIComponent(authResponse.state));
                if (type === "authentication") {
                    // Verify that stored nonce matches the one from state
                    if (localStorage.getItem("openid.nonce") === nonce) {
                        submitAuthCode({ nextUrl: nextURI });
                    }
                    else {
                        setErrorData({ ...errorData, error: true, message: "Nonce does not match" });
                    }
                }
                else if (type === "authorization") {
                    authorize({ nextUrl: nextURI, stateID: id });
                }
                else {
                    // Disregard state, submit id token and redirect user without nextURI if authenticated
                    submitAuthCode({ nextUrl: null });
                }
            }
            catch {
                //An error may have occurred when decoding state, submit id token and redirect user without nextURI if authenticated.
                submitAuthCode({ nextUrl: null });
            }
        }
    }, []);
    return (<PageLayout PageHeaderProps={{
            content: (<Typography variant="h6">
            <FormattedMessage id="login.authentication" defaultMessage="Authentication"/>
          </Typography>),
        }}>
      <RemoveSplash />
      <Section loading={isLoading} sx={({ spacing }) => ({ maxWidth: spacing(80), minHeight: spacing(20), m: "auto" })}>
        <Section.Content>
          {isLoading && (<>
              <Typography variant="h6">
                <FormattedMessage id="login.authentication.openId.awating.description" defaultMessage="Validating Provider results"/>
              </Typography>
              <Typography variant="caption">
                <FormattedMessage id="login.authentication.openId.awating.caption" defaultMessage="Please wait as your credentials are validated, and you are redirected to the correct location"/>
              </Typography>
            </>)}
          {error && (<>
              <LoginBoxCallout type="error">
                <FormattedMessage id="login.authentication.openId.failed" defaultMessage="Could not login with OpenId"/>
              </LoginBoxCallout>

              <Typography variant="h6">
                <FormattedMessage id="login.authentication.openId.failed.description" defaultMessage="Something went wrong login to ARGUS with Open id"/>
                <br />
              </Typography>

              <Typography variant="caption">
                {errorMessage} {data?.data?.timestamp}
              </Typography>
            </>)}
        </Section.Content>
      </Section>
    </PageLayout>);
}
export default OpenIDHandler;
