/**
 * Saves login details to local storage based on the response code of the authentication operation
 * or a bypass flag, and returns a boolean indicating if the operation was successful.
 *
 * @param {string} [username] - The username associated with the login attempt.
 * @param {("ldap" | "password" | "sms" | "totp" | "webauthn" | "openid")} method - The authentication method used.
 * @param {IResultstash<unknown>} [payload] - The payload object containing the response code and other data from the authentication operation.
 * @param {boolean} [byPassResponseCode=false] - A flag to bypass response code check and always treat the operation as successful.
 * @returns {boolean} A boolean value indicating whether the operation was successful, either due to a success response code or the bypass flag.
 *
 * @example
 * // Example of using the function on a successful authentication (responseCode 200 or 201)
 * const isSuccess = saveLoginDetails({ username: "john_doe", method: "password", payload });
 * @example
 * // Example of bypassing response code check
 * const isSuccess = saveLoginDetails({ method: "openid", byPassResponseCode: true });
 *
 */
export const saveLoginDetails = ({ username, method, payload, byPassResponseCode = false, }) => {
    const isSuccess = payload?.responseCode === 200 || payload?.responseCode === 201 || byPassResponseCode;
    if (isSuccess || byPassResponseCode) {
        if (username) {
            localStorage.setItem("login.lastUsername", `"${username}"`);
        }
        localStorage.setItem("login.lastMethod", `"${method}"`);
    }
    return isSuccess;
};
