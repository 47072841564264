import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RemoveSplash, SplashMessage } from "@mnemonic/mui5";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { fetchSession, fetchUser } from "@mne-authentication/api";
import { SessionContext } from "@mne-authentication/session2";
import { useTypedDispatch, useTypedSelector } from "../../Redux/createStore";
import { globalSlice } from "../../Redux/globalSlice";
import redirectUser from "../../utils/redirectUser";
const wrapper = (props) => {
    const { children } = props;
    const dispatch = useTypedDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { session } = useContext(SessionContext);
    const { nextUrl, loginSuccess, lastMethod, disableLastMethodForward, initialized } = useTypedSelector((state) => state.global);
    // Initialize on first mount
    useEffect(() => {
        if (!initialized) {
            dispatch(globalSlice.actions.initializeGlobal(location));
        }
    }, []);
    // User already logged in
    if (session && session.user) {
        onLoginSuccess({ nextUrl });
        return <SplashMessage text="User authenticated, redirecting"/>;
    }
    // Forward to last login method onMount
    useEffect(() => {
        if (!disableLastMethodForward && location.pathname === "/login") {
            navigate(`/login/${lastMethod}`);
        }
        dispatch(globalSlice.actions.setDisableLastMethodForward(true));
    }, []);
    // Successful login
    useEffect(() => {
        if (loginSuccess) {
            onLoginSuccess({ nextUrl });
        }
    }, [loginSuccess]);
    return (<>
      <RemoveSplash />
      {children}
    </>);
};
const onLoginSuccess = async ({ nextUrl }) => {
    const { domain, apiUrl } = ArgusUtils.getTargetMeta();
    const sessionQuery = await fetchSession({ domain }, { apiUrl });
    if (!sessionQuery.response.ok) {
        return null;
    }
    const userQuery = await fetchUser({ apiUrl });
    if (!userQuery.response.ok) {
        return null;
    }
    const changePasswordNeeded = userQuery?.json?.data?.flags?.includes("FORCE_PWCHANGE");
    redirectUser({ nextUrl, changePasswordNeeded });
    return userQuery;
};
export default wrapper;
