import { createIntl, createIntlCache, getLocaleFromLanguage } from "@mnemonic/intl";
import { defaultFormats, getDefaultBrowserTimeZone, setHour12 } from "@mnemonic/intl";
import { en, nb } from "../i18n/all";
const cache = createIntlCache();
const messagesByLocale = {
    "en-US": en,
    "nb-NO": nb,
};
const defaultLocale = "en-US";
const intl = createIntl({
    locale: defaultLocale,
    messages: messagesByLocale[defaultLocale],
}, cache);
export default async function initializeIntl(session) {
    if (!session) {
        return Promise.resolve(intl);
    }
    const locale = getLocaleFromLanguage(session?.user?.language);
    const isHour12 = session.user.properties["time.hour12"] === "true";
    const timeZone = getDefaultBrowserTimeZone();
    const formats = setHour12(defaultFormats, isHour12);
    return Promise.resolve(createIntl({
        locale,
        messages: messagesByLocale[locale],
        defaultFormats: formats,
        timeZone,
    }, createIntlCache()));
}
