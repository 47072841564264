import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { authenticateLdap, authenticatePassword, authenticateSMS, authenticateTotp, authenticateWebauthn, createActivateEnrollmentByCriteria, fetchBreachStatusByCriteria, updateUserPassword, verifyEnrollmentAndAuthorize, } from "@mne-authentication/api";
import { baseApi } from "./baseApi";
const authenticateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        /* Mutations */
        authenticateLdap: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateLdap(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticatePassword: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticatePassword(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        updatePassword: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(updateUserPassword(criteria, {
                apiUrl: ArgusUtils.getApiUrl(),
                sessionKey: metaOpts?.sessionKey,
            }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateTotp: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateTotp(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateSMS: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateSMS(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateWebauthn: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateWebauthn(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        activateEnrollment: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(createActivateEnrollmentByCriteria(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        fetchBreachStatus: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(fetchBreachStatusByCriteria(criteria, {
                apiUrl: ArgusUtils.getApiUrl(),
                sessionKey: metaOpts?.sessionKey,
            }), metaOpts),
        }),
        verifyAndAuthorize: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(verifyEnrollmentAndAuthorize(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
    }),
});
export const { useActivateEnrollmentMutation, useAuthenticateLdapMutation, useAuthenticatePasswordMutation, useAuthenticateSMSMutation, useAuthenticateTotpMutation, useAuthenticateWebauthnMutation, useFetchBreachStatusMutation, useUpdatePasswordMutation, useVerifyAndAuthorizeMutation, } = authenticateApi;
export default authenticateApi;
