import { createCsrfToken, createPutRequestOpts, fetchJsonQuery } from "@mne-core/api";
import { QueryController, createUrl } from "@mne-core/api";
export const createRequest = ({ ...restCriteria }, opts) => {
    const { apiUrl, sessionKey, ...restOpts } = opts;
    const uri = createUrl(apiUrl, ["authentication", "v1", "password", "test"]).toString();
    return new Request(uri, createPutRequestOpts(JSON.stringify(restCriteria), { ...restOpts, csrfToken: createCsrfToken(sessionKey, uri) }));
};
export const fetchBreachStatusByCriteria = async (criteria, opts) => fetchJsonQuery(createRequest(criteria, opts), {
    ctrl: opts.ctrl || new QueryController(),
});
