import React from "react";
import Grid from "@mui/material/Grid";
import { Section } from "@mnemonic/mui5/src/Section";
import { LeftSidebar } from "./LeftSidebar/LeftSidebar";
export const VerifyBox = (props) => {
    return (<Section loading={props.loading} sx={({ spacing }) => ({
            m: "auto",
            minHeight: "514px",
            marginTop: spacing(16),
            width: "776px",
        })}>
      <Section.Content sx={{ pt: 2, pr: 0, pl: 2 }}>
        <Grid container display="flex" spacing={2}>
          <LeftSidebar />
          <Grid item width="496px" sx={({ spacing }) => ({ padding: `${spacing(4)} !important` })}>
            {props.children}
          </Grid>
        </Grid>
      </Section.Content>
    </Section>);
};
