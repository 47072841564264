import React from "react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
export const TokenProblems = ({ cookie }) => {
    const slicedCookie = cookie.slice(-6);
    return (<Typography>
      <FormattedMessage id="login.smsCode.tokenText" defaultMessage="If you haven't received a text message with a token code within 2 minutes, please contact support and provide the following code"/>
      <Typography variant="body1" style={{ display: "inline", fontFamily: "monospace" }}>
        {` ${slicedCookie}`}
      </Typography>
    </Typography>);
};
