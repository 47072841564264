import React from "react";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useIsDarkMode } from "@mnemonic/mui5/styles";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import nfcertLogo from "../../../assets/nfcert.logo.dark.png";
import { getStaticResourceSrc } from "../../../utils/getStaticResourceSrc";
const StyledDiv = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));
const StyledImg = styled("img")(({ theme }) => ({
    display: "block",
    height: 40,
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
}));
const LoginBoxHeader = () => {
    const { metaInfo } = ArgusUtils.getTargetMeta();
    const isDarkMode = useIsDarkMode();
    const logo = metaInfo.theme === "mnemonic"
        ? getStaticResourceSrc(isDarkMode ? "images/argus-logo-light.svg" : "images/argus-logo-dark.svg")
        : nfcertLogo;
    const altText = metaInfo.theme === "mnemonic" ? "mnemonic" : "nfcert";
    return (<StyledDiv>
      <StyledImg src={logo} alt={altText}/>
      <Divider sx={(theme) => ({ marginBottom: theme.spacing(2) })}/>
    </StyledDiv>);
};
export default LoginBoxHeader;
