import React from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import AppStoreBadge from "../../../../../assets/AppStoreBadge.svg";
import GooglePlayBadge from "../../../../../assets/GooglePlayBadge.png";
const LeftSidebarArgusMobileHeader = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (<Box sx={{ py: theme.spacing(2), textAlign: "center" }}>
      <Grid container justifyContent="center">
        <Box sx={{ mt: theme.spacing(2) }}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            <FormattedMessage id="login.argus.mobile.footer.header" defaultMessage="Get Argus for Mobile"/>
          </Typography>
        </Box>
        <Box sx={{ mt: theme.spacing(1), px: theme.spacing(1) }}>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            <FormattedMessage id="login.argus.mobile.footer.description" defaultMessage="Get push notifications and manage your cases with our new app: Argus for Mobile. Free to use."/>
          </Typography>
        </Box>
        <Grid container sx={{ mt: theme.spacing(2) }}>
          <ButtonBase disableRipple onClick={() => navigate("/redirect/mobile-app")}>
            <img src={AppStoreBadge} style={{ marginRight: 8, height: 34 }}/>
          </ButtonBase>
          <ButtonBase disableRipple onClick={() => navigate("/redirect/mobile-app")}>
            <img src={GooglePlayBadge} style={{ marginLeft: 8, height: 34 }}/>
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>);
};
export default LeftSidebarArgusMobileHeader;
