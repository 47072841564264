export const setOpenIdFlowSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};
export const getOpenIdFlowObject = () => {
    return {
        initialNextUrl: sessionStorage.getItem("intialNex"),
        preAuthRequestState: sessionStorage.getItem("preAuthRequestState"),
        preAuthResponseState: sessionStorage.getItem("preAuthResponseState"),
        providerRequestState: sessionStorage.getItem("providerRequestState"),
        providerResponseState: sessionStorage.getItem("providerResponseState"),
        autoAuthenticate: sessionStorage.getItem("autoAuthenticate"),
    };
};
