import React from "react";
import { Form } from "react-final-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { defineMessages, useIntl } from "@mnemonic/intl";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxActions, LoginBoxCallout } from "../../Components/Login/Layout";
import { TextField } from "../../Components/MuiFinalForm";
import { useAuthorizeTotpMutation } from "../../Redux/Service/authorizeApi";
import { commonMessages } from "../../commonMessages";
import { redirectWithToken } from "./utils/redirectWithToken";
import validateEmptyField from "./utils/validateEmptyField";
export const messages = defineMessages({
    helperTextTotp: {
        id: "mne-authentication.userauthorization.totp.loginHelper.tokenCode",
        defaultMessage: "The code displayed on your TOTP device",
    },
});
export const AuthorizationWithTOTP = ({ operation, context, nextURI }) => {
    const [authorizeTotp, { isLoading, error }] = useAuthorizeTotpMutation();
    const intl = useIntl();
    const errorMessage = extractErrorMessage(error);
    return (<Form initialValues={{ tokenCode: null }} onSubmit={(formValues) => {
            return authorizeTotp({
                criteria: { ...formValues, operation, context, nextURI, tokenCode: formValues.tokenCode },
            })
                .unwrap()
                ?.then((payload) => redirectWithToken({ nextURI, payload }))
                .catch((e) => console.error(e));
        }} render={({ handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
            {errorMessage && (<Box sx={{ mb: 4 }}>
                <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>
              </Box>)}
            <>
              <TextField name="tokenCode" type="text" autoComplete="off" fullWidth label={intl.formatMessage(commonMessages.TokenCode)} helperText={intl.formatMessage(messages.helperTextTotp)} validate={(value) => validateEmptyField(value)}/>
              <LoginBoxActions>
                <div>
                  <Button disabled={isLoading} style={{ marginRight: 8 }} onClick={() => window.location.replace(nextURI)}>
                    {intl.formatMessage(commonMessages.Cancel)}
                  </Button>
                  <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                    {intl.formatMessage(commonMessages.Authorize)}
                  </Button>
                </div>
              </LoginBoxActions>
            </>
          </form>);
        }}/>);
};
