import { QueryController, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (criteria, opts) => {
    const { apiUrl, ...restOpts } = opts;
    const { provider, ...restCriteria } = criteria;
    const uri = new AuthenticationURI().setApiUrl(apiUrl).setSegment(["openid", "provider", provider, "authentication"]);
    const headers = createHeaders({
        Accept: "application/json",
        "Content-type": "application/json",
    });
    const localRequestOpts = {
        body: JSON.stringify(restCriteria),
        credentials: "include",
        headers,
        method: "POST",
        mode: "cors",
    };
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function requestRedirectURIByProviderID(criteria, opts) {
    return await fetchJsonQuery(createRequest(criteria, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default requestRedirectURIByProviderID;
