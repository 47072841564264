import React from "react";
import { Form } from "react-final-form";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBox, LoginBoxCallout, LoginBoxContent, LoginBoxHeader, LoginBoxSubHeader, } from "../../Components/Login/Layout";
import { LoginButtonWithDisclaimer } from "../../Components/LoginButtonWithDisclaimer";
import { PasswordField } from "../../Components/PasswordTextField";
import { UsernameTextField } from "../../Components/UsernameTextField";
import { useAuthenticateLdapMutation } from "../../Redux/Service/authenticateApi";
import { useTypedDispatch, useTypedSelector } from "../../Redux/createStore";
import { globalSlice } from "../../Redux/globalSlice";
import { commonMessages } from "../../commonMessages";
import RedirectWrapper from "./RedirectWrapper";
import { saveLoginDetails } from "./utils/saveLoginDetails";
const StyledDiv = styled("div")(({ theme }) => ({ paddingBottom: theme.spacing(2) }));
export const LoginLdap = () => {
    const { domain } = ArgusUtils.getTargetMeta();
    const [authenticateLdap, { isLoading, error }] = useAuthenticateLdapMutation();
    const { lastUsername } = useTypedSelector((state) => state.global);
    const dispatch = useTypedDispatch();
    const errorMessage = extractErrorMessage(error);
    return (<RedirectWrapper>
      <LoginBox loading={isLoading}>
        <LoginBoxHeader />
        <LoginBoxContent>
          {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
          <LoginBoxSubHeader method={<FormattedMessage {...commonMessages.AD3}/>}/>

          <Form initialValues={{ password: null, userName: lastUsername }} onSubmit={(formValues, formApi) => authenticateLdap({ criteria: { userName: formValues.userName, password: formValues.password, domain } })
            .unwrap()
            ?.then((payload) => {
            const isSuccess = saveLoginDetails({ username: formValues.userName, method: "ldap", payload });
            dispatch(globalSlice.actions.setLoginSuccess(isSuccess));
        })
            // on error reset password field
            ?.catch((error) => {
            formApi.change("password", "");
            console.error(error);
        })} render={({ values, handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
                  <StyledDiv>
                    <UsernameTextField name="userName" autoFocus={!values.userName}/>
                  </StyledDiv>
                  <StyledDiv>
                    <PasswordField name="password" autoFocus={Boolean(values.userName)} method="ad3"/>
                  </StyledDiv>
                  <LoginButtonWithDisclaimer disableButton={isLoading}/>
                </form>);
        }}/>
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
