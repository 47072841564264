import React from "react";
import { Form } from "react-final-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { defineMessages, useIntl } from "@mnemonic/intl";
import { LinearProgress } from "@mnemonic/mui5";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxActions, LoginBoxCallout } from "../../Components/Login/Layout";
import { TextField } from "../../Components/MuiFinalForm";
import { TokenProblems } from "../../Components/TokenProblems";
import { useAuthorizeSMSMutation, useRequestAuthorizationTokenSMSQuery } from "../../Redux/Service/authorizeApi";
import { commonMessages } from "../../commonMessages";
import { redirectWithToken } from "./utils/redirectWithToken";
import validateEmptyField from "./utils/validateEmptyField";
const messages = defineMessages({
    codeSMS: { id: "login.smsCode", defaultMessage: "SMS code" },
    helperTextSMS: { id: "login.helper.sms.token", defaultMessage: "Enter the code received on your phone" },
});
export const AuthorizationWithSMSToken = ({ context, nextURI, operation }) => {
    const { isLoading: isLoadingCookie, data, error: errorCookie, } = useRequestAuthorizationTokenSMSQuery({ criteria: undefined });
    const [authorizeSMS, { isLoading: isAuthorizeSMSLoading, error: errorAuthorizeSms }] = useAuthorizeSMSMutation();
    const intl = useIntl();
    const errorMessage = extractErrorMessage(errorAuthorizeSms || errorCookie);
    const isLoading = isLoadingCookie || isAuthorizeSMSLoading;
    return (<>
      <LinearProgress loading={isLoading}/>
      <Form initialValues={{ token: null }} onSubmit={(formValues) => authorizeSMS({ criteria: { ...formValues, operation, nextURI, context, cookie: data?.data?.cookie } })
            .unwrap()
            ?.then((payload) => redirectWithToken({ nextURI, payload }))} render={({ handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
              <TextField name="token" autoComplete="off" fullWidth helperText={intl.formatMessage(messages.helperTextSMS)} label={intl.formatMessage(messages.codeSMS)} validate={(value) => validateEmptyField(value)}/>
              <Box p={1}>{data?.data?.cookie && <TokenProblems cookie={data?.data?.cookie}/>}</Box>
              <LoginBoxActions>
                <div>
                  <Button disabled={isLoading} style={{ marginRight: 8 }} onClick={() => window.location.replace(nextURI)}>
                    {intl.formatMessage(commonMessages.Cancel)}
                  </Button>
                  <Button color="secondary" disabled={isLoading || isAuthorizeSMSLoading} type="submit" variant="contained">
                    {intl.formatMessage(commonMessages.Authorize)}
                  </Button>
                </div>
              </LoginBoxActions>
              {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
            </form>);
        }}/>
    </>);
};
