import { QueryController, createCsrfToken, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURIBuilder from "./AuthenticationURIBuilder";
export const createRequest = (body, opts) => {
    const { apiUrl, apiKey, sessionKey, ...restOpts } = opts;
    const { password, nextURI, operation, context } = body;
    const url = new AuthenticationURIBuilder().setApiUrl(apiUrl).setSegment(["password", "authorize"]);
    const headers = createHeaders({
        "ARGUS-API-KEY": apiKey,
        Accept: "application/json",
        "Content-type": "application/json",
        "ARGUS-CSRF-Token": !apiKey ? createCsrfToken(sessionKey, String(url)) : false,
    });
    const localRequestOpts = {
        body: JSON.stringify({ password, nextURI, operation, context }),
        credentials: "include",
        headers,
        method: "POST",
        mode: "cors",
    };
    return new Request(String(url), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function authorizePassword(body, opts) {
    return await fetchJsonQuery(createRequest(body, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authorizePassword;
