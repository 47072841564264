import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage, defineMessages, useIntl } from "@mnemonic/intl";
import { RemoveSplash, useSnackbar } from "@mnemonic/mui5";
import { SearchBar } from "@mnemonic/mui5";
import { PageLayout } from "@mnemonic/mui5/src/PageLayout";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { fetchAvailableDomains } from "@mne-authentication/api";
import { SessionContext, Unauthenticated } from "@mne-authentication/session2";
import { searchCustomersByCriteria } from "@mne-customer/api";
import { useFetchOauthClientsQuery } from "../../Redux/Service/openIdApi";
import RedirectToLoginPage from "./../../utils/RedirectToLoginPage";
import { Customers, Domain } from "./Filterboxes";
import Table from "./Table";
const DEFAULT_CRITERIA = {
    limit: 25,
    offset: 0,
    excludeFlags: ["hidden"],
};
const messages = defineMessages({
    searchPlaceholder: { id: "client.overview.keywords", defaultMessage: "Keywords" },
    fetchOauthClientsError: {
        id: "client.overview.fetchOauthClients.error",
        defaultMessage: "Error fetching oauth clients",
    },
});
const filterClients = (state, clients) => {
    if (state.showCustomerApps === state.showNonCustomerApps) {
        return clients;
    }
    else if (state.showCustomerApps) {
        return clients.filter((client) => client.customer);
    }
    else if (state.showNonCustomerApps) {
        return clients.filter((client) => !client.customer);
    }
    return clients;
};
const ClientOverviewPage = () => {
    const apiUrl = ArgusUtils.getApiUrl();
    const { session } = useContext(SessionContext);
    const [criteria, setCriteria] = useState(DEFAULT_CRITERIA);
    const [customers, setCustomers] = useState(null);
    const [domains, setDomains] = useState(null);
    const [keywordValue, setKeywordValue] = useState("");
    const { enqueue } = useSnackbar();
    const [customerCheckboxState, setCustomerCheckboxState] = useState({
        showCustomerApps: false,
        showNonCustomerApps: false,
    });
    const oAuthClientsResults = useFetchOauthClientsQuery({ criteria });
    const [fetchCustomerError, setCustomerError] = useState(false);
    const { formatMessage } = useIntl();
    const opts = { apiUrl, sessionKey: "" };
    if (session) {
        opts.sessionKey = session.info.sessionKey;
    }
    // Fetch customers and available domains on initial load
    useEffect(() => {
        searchCustomersByCriteria({ limit: 0 }, { apiUrl }).then(({ json, response }) => {
            if (response.ok) {
                setCustomers(json.data);
            }
            else {
                setCustomerError(true);
            }
        });
        fetchAvailableDomains({}, opts).then(({ json, response }) => {
            if (response.ok) {
                setDomains(json.data);
            }
        });
    }, []);
    if (oAuthClientsResults?.isError) {
        enqueue({
            variant: "error",
            title: formatMessage(messages.fetchOauthClientsError),
            message: extractErrorMessage(oAuthClientsResults.error) || formatMessage(messages.fetchOauthClientsError),
        });
    }
    return (<PageLayout PageHeaderProps={{
            content: (<Typography variant="h6">
            <FormattedMessage id="client.overview.title" defaultMessage="External applications"/>
          </Typography>),
        }}>
      {session && (<>
          <RemoveSplash />
          <Grid container direction="column">
            <Grid container direction="row" mb={2}>
              <SearchBar placeholder={formatMessage(messages.searchPlaceholder)} value={keywordValue} onChange={(event) => setKeywordValue(event.target.value)} onClickSearchIcon={() => setCriteria({ ...criteria, keywords: [keywordValue] })} onKeyDown={(event) => {
                if (event.keyCode === 13) {
                    setCriteria({ ...criteria, keywords: [keywordValue] });
                }
            }} onClickClearIcon={() => {
                setKeywordValue("");
            }}/>
              <Button variant="contained" color="secondary" onClick={() => setCriteria({ ...criteria, keywords: [keywordValue] })}>
                <FormattedMessage id="client.overview.search" defaultMessage="Search"/>
              </Button>
            </Grid>
            <Grid container direction="row" spacing={2} mb={2}>
              <Grid item>
                <Customers error={fetchCustomerError ? (<FormattedMessage id="client.overview.fetch.customers.error" defaultMessage="Could not fetch customers"/>) : null} onChange={(newCustomers) => setCriteria({ ...criteria, customer: newCustomers })} onCheckboxChange={(state) => setCustomerCheckboxState({
                showCustomerApps: state.showCustomerApplications,
                showNonCustomerApps: state.showNonCustomerApplications,
            })} options={customers || []} loading={!customers}/>
              </Grid>
              <Grid item>
                {domains?.length > 1 && (<Domain options={domains} onChange={(domain) => setCriteria({ ...criteria, domain: domain })}/>)}
              </Grid>
            </Grid>
            <Grid container>
              <Table clients={filterClients(customerCheckboxState, oAuthClientsResults?.data?.data || [])} domain={session?.user?.domain} loading={oAuthClientsResults?.isLoading} onChange={(sortBy) => setCriteria({ ...criteria, sortBy: [sortBy] })}/>
            </Grid>
          </Grid>
        </>)}
      <Unauthenticated>
        <RedirectToLoginPage />
      </Unauthenticated>
    </PageLayout>);
};
export default ClientOverviewPage;
