import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import baseApi from "./Service/baseApi";
import reducer from "./reducer";
export const useTypedSelector = useSelector;
export const useTypedDispatch = useDispatch;
export default function createStore({ extraArgument, preloadedState = {} }) {
    return configureStore({
        reducer,
        devTools: true,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: {
                extraArgument,
            },
            serializableCheck: false,
            immutableCheck: false,
        }).concat(baseApi.middleware),
        preloadedState,
    });
}
