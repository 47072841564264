import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
import ContactUsDialog from "../../Components/ContactUsDialog";
import { TextField } from "../../Components/MuiFinalForm";
import { SubmitButton } from "../../Components/SubmitButton";
import { UsernameTextField } from "../../Components/UsernameTextField";
import { useActivateEnrollmentMutation } from "../../Redux/Service/authenticateApi";
import { commonMessages } from "../../commonMessages";
import { validateEmptyField } from "../Login/utils/validate";
import { mapErrors } from "./utils";
const getBoxStyles = (showForm) => ({
    opacity: showForm ? 1 : 0,
    transition: "opacity 0.5s ease",
    pointerEvents: showForm ? "auto" : "none",
});
export const StepVerifyUser = ({ domain, autoSubmit, initialValues, setInitialValues, setEnrollFlowState, }) => {
    const [activateEnrollment, { isError, isUninitialized }] = useActivateEnrollmentMutation();
    const hasSubmitted = useRef(false);
    const showForm = !autoSubmit || (autoSubmit && !isUninitialized && isError);
    const [open, setOpen] = useState(false);
    return (<Box sx={getBoxStyles(showForm)}>
      <ContactUsDialog {...{ open, onClose: () => setOpen(false) }}/>
      <Typography variant="body1">
        <FormattedMessage id="login.verify.verifyUser.info" defaultMessage="To enroll your user, please enter username and enrollment code from your enrollment e-mail"/>
      </Typography>
      <Form initialValues={initialValues} onSubmit={async (formValues) => {
            try {
                setInitialValues({ userName: formValues.userName, enrollmentCode: formValues.enrollmentCode });
                const { data } = await activateEnrollment({ criteria: { domain, ...formValues } }).unwrap();
                return setEnrollFlowState({
                    step: { kind: "verifySmsCode" },
                    activationCookie: data?.activationCookie,
                    userId: data.userID,
                });
            }
            catch (e) {
                return mapErrors(e, ["enrollmentCode", "userName"]);
            }
        }} render={({ values, handleSubmit, submitError }) => {
            // Auto-submit logic using useEffect, only submits once
            useEffect(() => {
                if (autoSubmit && !hasSubmitted.current) {
                    hasSubmitted.current = true; // Set the flag to true to prevent re-submission
                    handleSubmit();
                }
            }, [autoSubmit, handleSubmit]);
            return (<form onSubmit={handleSubmit}>
              <Box sx={(theme) => ({ my: theme.spacing(3) })}>
                <UsernameTextField name="userName" autoFocus={true}/>
              </Box>
              <Box sx={(theme) => ({ mb: theme.spacing(1) })}>
                <TextField fullWidth label={<FormattedMessage {...commonMessages.EnrollmentCode}/>} name="enrollmentCode" type="text" validate={(value) => validateEmptyField(value)}/>
                <Typography variant="body2" mt={2}>
                  <FormattedMessage id="login.verify.missing" defaultMessage="Haven’t received e-mail or code expired?"/>
                  <Link onClick={() => setOpen(true)} style={{ color: "inherit", marginLeft: "4px" }}>
                    <FormattedMessage {...commonMessages.ContactUs}/>
                  </Link>
                </Typography>
              </Box>
              {submitError && (<Alert variant="outlined" severity="error">
                  {submitError}
                </Alert>)}
              <SubmitButton>
                <FormattedMessage id="login.verify.button.submit" defaultMessage="verify enrollment code"/>
              </SubmitButton>
            </form>);
        }}/>
    </Box>);
};
