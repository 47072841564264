import URI from "urijs";
const DEFAULT_API_SEGMENT = ["authentication", "v1"];
class AuthenticationURIBuilder {
    apiUrl;
    apiSegment;
    segment;
    query = {};
    constructor() {
        this.apiUrl = null;
        this.apiSegment = DEFAULT_API_SEGMENT;
    }
    setApiUrl(apiUrl) {
        this.apiUrl = apiUrl;
        const { path } = URI.parse(apiUrl);
        if (path && path !== "/") {
            this.apiSegment = [path, ...this.apiSegment];
        }
        return this;
    }
    setSegment(segment) {
        const localSegment = Array.isArray(segment) ? segment : segment.split("/");
        this.segment = localSegment;
        return this;
    }
    setQuery(query) {
        this.query = query;
        return this;
    }
    build() {
        const { apiUrl, apiSegment, segment, query } = this;
        return new URI(apiUrl).segment([...apiSegment, ...(segment ? segment : [])].map((s) => String(s))).setQuery(query);
    }
    toString() {
        return String(this.build());
    }
}
export default AuthenticationURIBuilder;
