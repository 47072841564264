import React from "react";
import Typography from "@mui/material/Typography";
import { Section } from "@mnemonic/mui5";
import { LoginBoxCallout, LoginBoxHeader } from "./Login/Layout";
const UserAuthorizationBase = ({ children, context, errorMessage, isLoading, title }) => (<Section loading={isLoading} sx={({ spacing }) => ({ maxWidth: spacing(80), m: "auto" })}>
    <LoginBoxHeader />
    <Section.Header title={title}/>
    <Section.Content>
      {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
      <Typography style={{ marginBottom: context?.length > 1 ? 16 : 0 }}>
        {Array.isArray(context)
        ? context?.map((value) => (<li key={value} style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                {value}
              </li>))
        : context}
      </Typography>
      {children}
    </Section.Content>
  </Section>);
export default UserAuthorizationBase;
