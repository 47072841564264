import { QueryController, createCsrfTokenFromURI, createHeaders, fetchJsonQuery } from "@mne-core/api";
import CustomersURI from "./CustomersURIBuilder";
export const createRequest = (queryParams, opts) => {
    const { apiUrl, apikey, sessionKey, ...restOpts } = opts;
    const uri = new CustomersURI().setApiUrl(apiUrl).setSegment(["domain"]).setQuery(queryParams).build();
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
        "Argus-CSRF-Token": !apikey ? createCsrfTokenFromURI(sessionKey, uri) : null,
        "Content-type": "application/json",
    });
    let localRequestOpts = {
        headers,
        method: "GET",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function fetchAvailableDomains(queryParams, opts) {
    return await fetchJsonQuery(createRequest(queryParams, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default fetchAvailableDomains;
