import React from "react";
// import { Link } from "react-router-dom";
// import HowToReg from "@mui/icons-material/HowToReg";
// import InfoOutlined from "@mui/icons-material/InfoOutlined";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import Typography from "@mui/material/Typography";
// import { FormattedMessage } from "@mnemonic/intl";
// import { toSpaUrl } from "@mnemonic/pure-utils";
// import { AuthenticationMethodsList } from "../../Components/AuthenticationMethodsList";
import { RedirectBox } from "./RedirectBox";
// import type { IEnrollUsernamePassword } from "./useVerifyUsernamePassword";
// interface IStepCompleteProps extends IEnrollUsernamePassword {}
/**
 *  This was commented out as we are not ready for this logic in production yet
 * The jump into user pages are not good enough and the redux logic in there is hard
 * to refactor to adapt for this jump between pages and enrollment on the fly
 *
 * */
export const StepComplete = () => {
    // const unConfiguredPreferredAuthMethods =
    //   authSetup?.unConfiguredPreferredAuthMethods || (!authSetup?.listItems?.length && !isLoading);
    return <RedirectBox />;
    // return (
    //   <Grid
    //     container
    //     direction="column"
    //     justifyContent="space-between"
    //     alignItems="center"
    //     sx={({ spacing }) => ({ minHeight: spacing(64) })} // Ensures full height container
    //   >
    //     <Grid item>
    //       <HowToReg sx={{ fontSize: 80 }} />
    //     </Grid>
    //     <Grid item>
    //       <Typography variant="subtitle1" color="textPrimary" sx={{ mb: 1 }}>
    //         <FormattedMessage id="setup.complete" defaultMessage="Setup is completed!" />
    //       </Typography>
    //     </Grid>
    //     <Grid item>
    //       <Typography variant="body2" color="textSecondary">
    //         <FormattedMessage
    //           id="setup.complete.secure"
    //           defaultMessage="Would you like to setup a more secure login method?"
    //         />
    //       </Typography>
    //     </Grid>
    //     <Grid item mt={4}>
    //       <Typography variant="h6">
    //         <FormattedMessage id="setup.complete.secure.yes" defaultMessage="Yes, please set up my account using" />
    //       </Typography>
    //     </Grid>
    //     <Grid item mt={4} flexGrow={1}>
    //       <AuthenticationMethodsList methods={authSetup.listItems} handleClick={authSetup.handleClick} />
    //     </Grid>
    //     <Grid item mt={4}>
    //       <Box display="flex" alignItems="center">
    //         <Link
    //           to={toSpaUrl("/dashboard")}
    //           style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center" }}
    //         >
    //           <Typography variant="body1">
    //             <FormattedMessage
    //               id="setup.complete.secure.no"
    //               defaultMessage="No thanks, keep SMS as my primary log in method"
    //             />
    //           </Typography>
    //         </Link>
    //         <Tooltip
    //           title="We recommend you setup another factor than SMS. Such as TOTP or a security key. SMS will remain your default login method. You can change this later in your settings."
    //           placement="top"
    //         >
    //           <IconButton sx={{ ml: 1 }}>
    //             <InfoOutlined fontSize="small" />
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     </Grid>
    //   </Grid>
    //  );
};
