import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { defineMessages, useIntl } from "@mnemonic/intl";
import { RemoveSplash } from "@mnemonic/mui5";
import { PageLayout } from "@mnemonic/mui5/src/PageLayout";
import { SessionContext, Unauthenticated } from "@mne-authentication/session2";
import UserAuthorizationBase from "../../Components/UserAuthorizationBase";
import RedirectToLoginPage from "../../utils/RedirectToLoginPage";
import { AuthorizationWithLDAP } from "./AuthorizationWithLDAP";
import { AuthorizationWithOpenID } from "./AuthorizationWithOpenID";
import { AuthorizationWithSMSToken } from "./AuthorizationWithSMSToken";
import { AuthorizationWithTOTP } from "./AuthorizationWithTOTP";
import { AuthorizationWithUsernameAndPassword } from "./AuthorizationWithUsernameAndPassword";
import { AuthorizationWithWebAuthn } from "./AuthorizationWithWebAuthn";
import ErrorCard from "./ErrorCardQueryParameters";
import { getOperationTitle, getUserAuthSearch } from "./utils/OperationUtils";
const messages = defineMessages({
    userAuthorization: { id: "user.authorization", defaultMessage: "User Authorization" },
});
export const AuthorizationPage = () => {
    const { session } = useContext(SessionContext);
    const { context, next: nextURI, operation } = getUserAuthSearch(location.href);
    const intl = useIntl();
    const showErrorCard = !(Boolean(operation) && Boolean(nextURI));
    const allowAuthorization = Boolean(operation) && Boolean(nextURI);
    const title = getOperationTitle(operation, intl);
    const authenticationMethod = session.info.authenticationMethod;
    const sharedProps = {
        context: Object.assign({}, context),
        nextURI,
        operation,
    };
    const authorizationMethods = {
        LDAP: <AuthorizationWithLDAP {...sharedProps}/>,
        SMS: <AuthorizationWithSMSToken {...sharedProps}/>,
        PASSWORD: <AuthorizationWithUsernameAndPassword {...sharedProps}/>,
        TOTP: <AuthorizationWithTOTP {...sharedProps}/>,
        OPENID: <AuthorizationWithOpenID {...sharedProps}/>,
        SIGNATURE: <AuthorizationWithWebAuthn {...sharedProps}/>,
    };
    return (<PageLayout PageHeaderProps={{
            content: <Typography variant="h6">{intl.formatMessage(messages.userAuthorization)}</Typography>,
        }}>
      {session && (<>
          <RemoveSplash />
          {showErrorCard && <ErrorCard {...{ intl }}/>}
          {allowAuthorization && (<UserAuthorizationBase {...{ title, context }}>
              {authorizationMethods[authenticationMethod]}
            </UserAuthorizationBase>)}
        </>)}
      <Unauthenticated>
        <RedirectToLoginPage />
      </Unauthenticated>
    </PageLayout>);
};
