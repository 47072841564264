import React from "react";
import { styled } from "@mui/material/styles";
const StyledDiv = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));
const LoginBoxContent = (props) => {
    const { children } = props;
    return <StyledDiv>{children}</StyledDiv>;
};
export default LoginBoxContent;
