import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
const StyledRootDiv = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
const StyledLinkSpan = styled("span")(({ theme }) => ({
    "&:focus,&:active": {
        color: theme.palette.secondary.light,
    },
    "&:hover": {
        color: theme.palette.secondary.dark,
    },
    color: theme.palette.secondary.main,
}));
const LoginBoxSubHeader = (props) => {
    const { method } = props;
    return (<StyledRootDiv>
      <Typography variant="h5">
        {method} <FormattedMessage id="login.authentication" defaultMessage="Authentication"/>
      </Typography>
      <Link to="/login">
        <StyledLinkSpan>
          <FormattedMessage id="login.change" defaultMessage="Change?"/>
        </StyledLinkSpan>
      </Link>
    </StyledRootDiv>);
};
export default LoginBoxSubHeader;
