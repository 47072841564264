import React, { useState } from "react";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxCallout } from "../../Components/Login/Layout";
import { TextField } from "../../Components/MuiFinalForm";
import { useRequestOpenIdProviderLinkQuery } from "../../Redux/Service/openIdApi";
import { isValidEmail, messages, validateEmail } from "./utils/openId";
export const LoginOpenIDMissingProvider = () => {
    const intl = useIntl();
    const { metaInfo } = ArgusUtils.getTargetMeta();
    const [email, setEmail] = useState("");
    const { isSuccess, isLoading, error } = useRequestOpenIdProviderLinkQuery({ criteria: { email } }, { skip: !isValidEmail(email) });
    const handleSubmit = (formValues) => {
        setEmail(formValues.email);
    };
    const errorMessage = extractErrorMessage(error);
    return (<Grid container spacing={2}>
      <Grid item>
        <Typography>
          <FormattedMessage id="login.openid.providerId.missing" defaultMessage="To log in with OpenID, we need your Provider ID. 
          If you don't have this information, we can send you a link."/>
        </Typography>
      </Grid>

      <Grid item>
        <Typography>
          {metaInfo.theme === "mnemonic" ? (<FormattedMessage id="login.contact.mss.enrollment" defaultMessage="If you are not enrolled with Open Id, please contact mss@mnemonic.no to receive enrollment"/>) : (<FormattedMessage id="login.contact.nfcert.enrollment" defaultMessage="If you are not enrolled with Open Id, please log in to https://apps.nfcert.org and click the 'Cases' button to configure your account with OpenID"/>)}
        </Typography>
      </Grid>

      <Grid item>
        <Form onSubmit={handleSubmit} initialValues={{ email: "" }} render={({ handleSubmit, submitting, pristine }) => (<form onSubmit={handleSubmit}>
              <TextField sx={{ width: "100%" }} name="email" type="text" autoComplete="off" fullWidth label={intl.formatMessage(messages.email)} helperText={intl.formatMessage(messages.helperText)} validate={(value) => validateEmail(value, intl)}/>

              <Button disabled={submitting || pristine || isLoading} type="submit" variant="contained" color="secondary" sx={{ mt: 2 }}>
                <FormattedMessage id="login.openid.providerId.submit" defaultMessage="Submit"/>
              </Button>
            </form>)}/>
      </Grid>

      <Grid item>
        {isSuccess && (<Typography>
            <FormattedMessage id="login.openid.providerId.sent" defaultMessage="An email with provider info is sent to: "/>
            {email}
          </Typography>)}

        {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
      </Grid>
    </Grid>);
};
