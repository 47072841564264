import React from "react";
import URI from "urijs";
import { SplashMessage } from "@mnemonic/mui5";
const RedirectToLoginPage = () => {
    const nextPath = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
    const redirectUrl = new URI(window.location.origin)
        .pathname("spa/authentication/login")
        .query(`next=${nextPath}`)
        .toString();
    React.useEffect(() => {
        window.location.replace(redirectUrl);
    }, []);
    return <SplashMessage text={`You are being redirected to ${redirectUrl}`}/>;
};
export default RedirectToLoginPage;
