import React from "react";
import "core-js/stable";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "regenerator-runtime/runtime";
import Application from "./Application";
import PromiseBootstrap from "./Bootstrap";
import createStore from "./Redux/createStore";
import { defaultState } from "./Redux/globalSlice";
import Content from "./index";
document.addEventListener("DOMContentLoaded", () => {
    const root = createRoot(document.getElementById("root"));
    PromiseBootstrap().then(({ session, intl, target }) => {
        try {
            const store = createStore({
                extraArgument: { target, session, intl },
                preloadedState: {
                    global: {
                        ...defaultState,
                        lastMethod: JSON.parse(localStorage.getItem("login.lastMethod")) || "",
                        lastUsername: JSON.parse(localStorage.getItem("login.lastUsername")) || "",
                    },
                },
            });
            root.render(<Provider store={store}>
          <Application {...{ session, intl, target }}>
            <Content />
          </Application>
        </Provider>);
        }
        catch (ex) {
            console.error(ex);
        }
    });
});
