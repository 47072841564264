import "./publicPath";
import React, { useContext, useEffect } from "react";
import { Routes } from "@mnemonic/router";
import { SessionContext } from "@mne-authentication/session2";
import initializeLogging from "../src/utils/initializeLogging";
import { routeDefinitions } from "./Routes/routeDefinitions";
import { getOpenIdFlowObject } from "./utils/openIdFlowSessionStorage";
const Comp = () => {
    const { session } = useContext(SessionContext);
    useEffect(() => {
        if (session) {
            const openIdFlow = getOpenIdFlowObject();
            initializeLogging({ session }).then((logger) => {
                logger.logInfo("openIdFlow", openIdFlow);
            });
        }
    }, []);
    return <Routes context={{ session }} routeDefinitions={routeDefinitions}/>;
};
export default Comp;
