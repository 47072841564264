import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
import PolicyDialog from "./GDPRPolicyDialog";
const StyledLink = styled("a")(({ theme }) => ({
    olor: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "underline",
}));
const DisclaimerComp = () => {
    const [open, setOpen] = useState(false);
    const linkProps = {
        onClick: () => setOpen(true),
        onKeyPress: () => setOpen(true),
        tabIndex: 0,
    };
    return (<>
      <PolicyDialog {...{ open, onClose: () => setOpen(false) }}/>
      <Box>
        <Typography variant="caption" display="flex" alignItems="flex-end">
          <FormattedMessage id="login.by.logging.in" defaultMessage="By logging in you agree to our"/>
          &nbsp;
          <StyledLink {...linkProps}>
            <FormattedMessage id="login.cookie.policy" defaultMessage="cookie policy"/>
          </StyledLink>
        </Typography>

        <Typography variant="caption" display="flex" alignItems="flex-end">
          <FormattedMessage id="login.problems" defaultMessage="You can see our service status on"/>
          &nbsp;
          <Link href="https://mnemonic.statuspage.io/" target="_blank" rel="noopener noreferrer" sx={{ cursor: "pointer" }}>
            mnemonic.statuspage.io
          </Link>
        </Typography>
      </Box>
    </>);
};
export default DisclaimerComp;
