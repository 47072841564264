import React from "react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { Section } from "@mnemonic/mui5";
import { PageLayout } from "@mnemonic/mui5/src/PageLayout";
import { ArgusMobileFooter } from "./ArgusMobileFooter";
const LoginBox = ({ children, loading }) => {
    return (<PageLayout PageHeaderProps={{
            content: (<Typography variant="h6">
            <FormattedMessage id="login.authentication" defaultMessage="Authentication"/>
          </Typography>),
        }}>
      <Section loading={loading} sx={({ spacing }) => ({ maxWidth: spacing(80), m: "auto" })}>
        <Section.Content>{children}</Section.Content>
        <ArgusMobileFooter />
      </Section>
    </PageLayout>);
};
export default LoginBox;
