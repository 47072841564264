import React from "react";
import { FormattedMessage } from "@mnemonic/intl";
export const validateEmptyField = (value) => {
    if (value != null && value.trim() !== "")
        return undefined;
    return <FormattedMessage id="validation.this.field.is.required" defaultMessage="This field is required"/>;
};
export const validatePassword = (value) => {
    const hasValue = validateEmptyField(value);
    if (hasValue)
        return hasValue;
    if (value?.length >= 8)
        return undefined;
    return (<FormattedMessage id="validation.length.password" defaultMessage="Your password must be at least 8 characters long"/>);
};
