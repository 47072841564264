import URI from "urijs";
const DEFAULT_API_SEGMENT = ["currentuser", "v1"];
class CurrentuserURIBuilder {
    apiUrl;
    apiSegment;
    segment;
    search = {};
    constructor() {
        this.apiUrl = null;
        this.apiSegment = DEFAULT_API_SEGMENT;
    }
    setApiUrl(apiUrl) {
        this.apiUrl = apiUrl;
        const { path } = URI.parse(apiUrl);
        if (path && path !== "/") {
            this.apiSegment = [path, ...this.apiSegment];
        }
        return this;
    }
    setSegment(segment) {
        const localSegment = Array.isArray(segment) ? segment : segment.split("/");
        this.segment = localSegment;
        return this;
    }
    setSearch(search) {
        this.search = search;
        return this;
    }
    build() {
        const { apiUrl, apiSegment, segment, search } = this;
        return new URI(apiUrl)
            .segment([...apiSegment, ...(segment ? segment : [])].map((s) => String(s)))
            .setSearch(search);
    }
    toString() {
        return String(this.build());
    }
}
export default CurrentuserURIBuilder;
