import { QueryController, createCsrfToken, createHeaders, fetchJsonQuery } from "@mne-core/api";
import AuthenticationURI from "./AuthenticationURIBuilder";
export const createRequest = (body, opts) => {
    const { apiUrl, apiKey, sessionKey, ...restOpts } = opts;
    const uri = new AuthenticationURI().setApiUrl(apiUrl).setSegment(["openid", "authorize"]);
    const { stateID, idToken } = body;
    const headers = createHeaders({
        "ARGUS-API-KEY": apiKey,
        Accept: "application/json",
        "Content-type": "application/json",
        "ARGUS-CSRF-Token": !apiKey ? createCsrfToken(sessionKey, String(uri)) : false,
    });
    let localRequestOpts = {
        body: JSON.stringify({ stateID, idToken }),
        headers,
        method: "POST",
        mode: "cors",
    };
    if (!apiKey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return new Request(String(uri), {
        ...localRequestOpts,
        ...restOpts,
    });
};
async function authorizeOpenID(body, opts) {
    return await fetchJsonQuery(createRequest(body, opts), {
        ctrl: opts.ctrl || new QueryController(),
    });
}
export default authorizeOpenID;
