import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
export const AD3Icon = () => (<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.875 19.5V10.9688L14.625 8.82375V19.3538L4.875 19.5ZM32.5 4.875V19.0938L16.25 19.3375V8.46625L32.5 4.875ZM4.875 21.125L14.625 21.2712V32.3375L4.875 30.4688V21.125ZM32.5 21.5312V35.75L16.25 32.6462V21.2875L32.5 21.5312Z" fill="black"/>
  </svg>);
export const OpenIDIcon = () => (<SvgIcon viewBox="0 0 259 259" sx={{ width: 35, height: 35 }}>
    <g clipPath="url(#clip0_3308_64)">
      <path d="M121.899 249.034V33.3815L155.878 9.95569V230.308L121.899 249.034Z" fill="#030104"/>
      <path d="M112.521 80.2735V109.573C112.521 109.573 45.3526 114.85 41.0381 165.844C41.0381 165.844 31.6192 210.366 112.521 224.434V249.044C112.521 249.044 -1.17485 237.347 0.010114 164.669C0.010114 164.669 -1.16472 90.8267 112.521 80.2735Z" fill="#030104"/>
      <path d="M164.071 80.2735V107.639C164.071 107.639 185.765 107.345 203.915 122.456L184.023 130.629L259 149.416V90.8166L233.407 105.299C233.407 105.299 211.561 82.3396 164.071 80.2735Z" fill="#030104"/>
    </g>
    <defs>
      <clipPath id="clip0_3308_64">
        <rect width="259" height="259" fill="white"/>
      </clipPath>
    </defs>
  </SvgIcon>);
