import React from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useIntl } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxActions, LoginBoxCallout } from "../../Components/Login/Layout";
import { useAuthorizeWebauthnMutation, usePrepareWebauthnAuthorizationQuery } from "../../Redux/Service/authorizeApi";
import { commonMessages } from "../../commonMessages";
import { createAuthorizeRequest } from "../Login/utils/webAuthRequestMappingUtils";
import { redirectWithToken } from "./utils/redirectWithToken";
export const AuthorizationWithWebAuthn = ({ nextURI, operation }) => {
    const { formatMessage } = useIntl();
    const [authorizeWebauthn, { isLoading: authorizeIsLoading, error: authorizeError }] = useAuthorizeWebauthnMutation();
    const { isLoading: prepareIsLoading, data, error: prepareError, } = usePrepareWebauthnAuthorizationQuery({ criteria: undefined });
    const isLoading = authorizeIsLoading || prepareIsLoading;
    const errorMessage = extractErrorMessage(authorizeError || prepareError);
    const handleAuthorizeWebauthn = () => {
        createAuthorizeRequest({ webauthnAuthorizationResponse: data?.data, operation, nextURI }).then((criteria) => {
            authorizeWebauthn({ criteria })
                .unwrap()
                ?.then((payload) => redirectWithToken({ nextURI, payload }))
                .catch((e) => console.error(e));
        });
    };
    return (<>
      <Grid container justifyContent="center">
        {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
        <FingerprintIcon sx={(theme) => ({ height: theme.spacing(16), width: theme.spacing(16), padding: theme.spacing(1) })}/>
      </Grid>
      <LoginBoxActions>
        <div>
          <Button disabled={isLoading} style={{ marginRight: 8 }} onClick={() => window.location.replace(ArgusUtils.toSpaUrl(nextURI))}>
            {formatMessage(commonMessages.Cancel)}
          </Button>
          <Button variant="contained" color="secondary" disabled={isLoading} onClick={() => handleAuthorizeWebauthn()}>
            {formatMessage(commonMessages.Authorize)}
          </Button>
        </div>
      </LoginBoxActions>
    </>);
};
